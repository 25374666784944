import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useHeader } from '../header'
import SmartTable from '../smartforms/table'
import { useApi } from '../../services/api'

function Index() {
  const { t } = useTranslation()

  const { title } = useHeader(t('lbl.publicacao'), t('lbl.ocultar'), 'fal fa-paper') // informa os dados do cabeçalho interno
  const [loading, data] = useApi(`/denuncia/pesquisa`, title) // busca todos os registros

  // Colunas para exibir na tabela
  const columns = [

    {
      Header: t('lbl.nome'),
      accessor: 'nome',
      style: {
        width: 450,
        minWidth: 200,
      }
    },

    {
      Header: t('lbl.email'),
      accessor: 'email',
      style: {
        width: 450,
        minWidth: 200,
      }
    },

    {
      Header: t('lbl.postagem'),
      accessor: 'postagem',
      style: {
        width: 450,
        minWidth: 200,
      }
    },

    {
      Header: t('lbl.qtde_denuncias'),
      accessor: 'qtde_denuncias',
      style: {
        width: 450,
        minWidth: 200,
      }
    },

    {
      Header: t('lbl.situacao'),
      accessor: 'situacao',
      style: {
        width: 450,
        minWidth: 200,
      }
    },
  ]

  if (loading) return <div>{t('txt.busca_registros')}</div>

  console.log('Render: denuncia - Pesquisa')
  return (
    <Fragment>
      <div className='panel'>
        <div className='panel-container'>
          <div className='panel-content'>
            <SmartTable
              columns={columns}
              data={data}
              route='/denuncia'
              propID='id_feed'
              deleteClass={() => { return 'disabled' }}
              detailsClass={() => { return 'disabled' }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Index
