import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import { useSelector, useDispatch } from 'react-redux'

import TokenService from '../services/token'
import api from '../services/api'
import ContentHeader from './header'
import PageHeader from './page'
import RoutesContent from './routes'
import Login from './login/login'
import ForgotPassword from './login/forgotPassword'
import ChangePassword from './login/changePassword'
import Sidebar from './sidebar'

import { login, logout } from '../stores/userSlice'

function AppManager(props) {
  const authenticated = useSelector(state => state.user.authenticated) // pega o status autenticado do redux
  const dispatch = useDispatch()

  const [userRequest, setUserRequest] = useState({
    loading: true,
    searchToken: true,
  })

  const onLogin = (token, name, change_password) => {
    TokenService.updateLocalAccessToken(token)
    setUserRequest({
      loading: false,
      searchToken: false
    })
    dispatch(login({ name: name, change_password }))
  }

  useEffect(() => {
    async function iniciarAplicacao() {
      console.log('Autenticando...')

      // autentica a token do usuário
      await api
        .get('/auth/me')
        .then(async res => {
          console.log('Autenticado!')

          setUserRequest({
            loading: false,
            searchToken: false
          })

          dispatch(login({
            name: res.data.name,
            change_password: res.data.change_password
          }))

        })
        .catch(error => {
          setUserRequest({
            loading: false,
            searchToken: false,
          })

          dispatch(logout())
        })
    }

    if (userRequest.searchToken) iniciarAplicacao()

  }, [userRequest, dispatch])

  // Carregando...
  if (userRequest.loading)
    return null

  // Página de login
  if (!authenticated) {
    return (
      <Router>
        <Route path='/login' render={() => <Login setToken={onLogin} />} />
        <Route path='/forgotPassword' render={() => <ForgotPassword />} />
        <Route path='/changePassword' render={() => <ChangePassword />} />
        <Redirect to='/login' />
      </Router>
    )
  }

  console.log('Render: App')
  return (
    <div className='wrapper'>
      <Router>
        <Sidebar />
        <div id='content'>
          <ContentHeader />
          <div className='page-content'>
            <PageHeader />
            <RoutesContent />
          </div>
        </div>
        <NotificationContainer />
      </Router>
    </div>
  )
}

export default AppManager
