import React, { Fragment, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHeader } from '../header'
import Item from './_detalhes'

import api, { useApi } from '../../services/api'



function Excluir(props) {
  const history = useHistory()
  const { t } = useTranslation()
  let { id } = useParams()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { title } = useHeader(t('lbl.local'), t('lbl.excluir'), 'fal fa-user') // informa os dados do cabeçalho interno
  const [ loading, data ] = useApi(`/local/detalhes/${id}`, title) // busca dados do registro

  const excluir = () => {
    setIsSubmitting(true)

    const header = t('msg.excluir_registro', { $1: title })

    api
      .delete(`/local/${id}`)
      .then(res => {
        NotificationManager.success(t('txt.registro_excluido'), header, 2000) // exibe um alerta de sucesso
        history.push('../pesquisa') // retorna para a tela de pesquisa
      })
      .catch(error => {
        NotificationManager.success(t('err.excluir_registro'), header) // exibe um alerta de erro
        setIsSubmitting(false)
      })
  }

  if (loading) return <div>{t('txt.busca_registro')}</div>

  console.log('Render: local - Excluir')
  return (
    <Fragment>
      <Item data={data} />
      <div className='page-footer'>
        <div className='row no-gutters'>
          <div className='col-sm-12 col-md-6'></div>
          <div className='col-sm-12 col-md-6 text-right'>
            <button className='btn btn-info' onClick={history.goBack}>
              {t('lbl.voltar')}
            </button>
            <button
              type='submit'
              className='btn btn-danger'
              disabled={isSubmitting}
              onClick={excluir}
            >
              <i className='fal fa-trash-alt'></i>
              {isSubmitting ? t('lbl.excluindo_') : t('lbl.excluir')}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Excluir
