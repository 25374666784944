import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'
import { useHeader } from '../header'
import { SmartCheckBox, SmartConditionalInput, SmartForm, SmartInput, SmartSelect, SmartTextarea } from '../smartforms/controls'
import api, { useApi } from '../../services/api'
import useSchema from './schema'

function Novo() {
  const { t } = useTranslation()
  const history = useHistory()
  const schema = useSchema()

  const { title } = useHeader(t('lbl.categoria'), t('lbl.novo'), 'fal fa-user') // informa os dados do cabeçalho interno
  const [categoriasloading, categorias] = useApi('/categoria/pesquisa', title) // busca dados das categorias

  const [selectedImage, setSelectedImage] = useState()
  const [categoriasPai, setCategoriasPai] = useState([])
  const [ordemHidden, setOrdemHidden] = useState(false)

  const [ativo, setAtivo] = useState(true)

  const [isDirty, setIsDirty] = useState(false)

  const changeOrdenacao = async (index, value) => {

    const pais = [...categoriasPai]
    pais[index].value = value
    setCategoriasPai(pais)

  }

  console.log('Render: categoria - Novo')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            isDirty={isDirty}
            config={{
              resolver: yupResolver(schema), defaultValues: {
                ativo: true
              },
            }}
            onSubmit={async data => {
              const header = t('txt.novo_registro', { $1: title })

              var formData = new FormData()
              formData.append('nome', data.nome)
              formData.append('ordenacao', data.ordenacao)
              formData.append('descricao', data.descricao)
              formData.append('exibir_preferencias', data.exibir_preferencias)
              formData.append('manter_fixo', data.manter_fixo)
              formData.append('ativo', data.ativo)

              if (categoriasPai && categoriasPai.length > 0) {
                const pais = categoriasPai.map(x => {
                  return {
                    id_categoria_pai: x.id_categoria_pai,
                    ordem: x.value || 0
                  }
                })
                for (const index in pais) {
                  for (const key in pais[index]) {
                    formData.append(`CategoriasPai[${index}][${key}]`, pais[index][key])
                  }
                }
              }

              if (selectedImage)
                formData.append('media', selectedImage.file)

              await api
                .post(`/categoria/novo`, formData, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(response => {
                  NotificationManager.success(t('txt.registro_salvo'), header, 2000) // exibe alerta de sucesso
                  history.push('./pesquisa') // volta pra tela de pesquisa
                })
                .catch(err => {
                  // erro de validação
                  if (err.response.status === 422) {
                    throw err.response.data.errors
                  }

                  // outro tipo de erro
                  else {
                    NotificationManager.error(t('err.salvar_registro'), header) // exibe alerta padrão
                  }
                })
            }}
          >
            <div className='row'>
              <div className='col-sm-12 col-md-6'>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartSelect
                      id='CategoriasPai'
                      label={t('lbl.categorias_pai')}
                      name='CategoriasPai'
                      placeholder={t('lbl.selecione')}
                      multiple={true}
                      onSelected={(items) => {
                        const selected = categorias.filter(x => items.includes(x.id_categoria))

                        const novocategoriasPai = items
                          ? items.map(x => {
                            const cat = categoriasPai.find(s => s.id_categoria_pai === x)
                            const catdata = categorias.find(c => c.id_categoria === x)
                            return {
                              id_categoria_pai: cat?.id_categoria_pai || x,
                              nome: catdata.nome,
                              value: cat?.value || ''
                            }
                          }).sort((a, b) => { return a.nome.localeCompare(b.nome) })
                          : null
                        setCategoriasPai(novocategoriasPai)

                        if (!selected || selected.length === 0)
                          setOrdemHidden(false)
                        else
                          setOrdemHidden(true)
                      }}
                      options={!categoriasloading && categorias
                        ? categorias.map(item => {
                          return {
                            value: item.id_categoria,
                            label: item.nome
                          }
                        }).sort((a, b) => { return a.label.localeCompare(b.label) })
                        : []}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='nome'
                      label={t('lbl.nome')}
                      name='nome'
                      placeholder={t('lbl.nome')}
                    />
                  </div>
                </div>

                {!ordemHidden &&
                  <div className='form-group row'>
                    <div className='col-sm-12 col-md-12'>
                      <SmartInput
                        id='ordenacao'
                        label={t('lbl.ordenacao')}
                        name='ordenacao'
                        placeholder={t('lbl.ordenacao')}
                      />
                    </div>
                  </div>
                }

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartTextarea
                      rows={5}
                      id='descricao'
                      label={t('lbl.descricao')}
                      name='descricao'
                      placeholder={t('lbl.descricao')}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-6 col-md-6'>
                    <label className='field-label'>Foto</label>
                    <br />
                    <input
                      type="file"
                      id="media"
                      style={{ marginTop: '10px' }}
                      onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                          setIsDirty(true)
                          let reader = new FileReader()
                          reader.onload = (e) => {
                            setSelectedImage({
                              image: e.target.result,
                              file: event.target.files[0]
                            })
                          }
                          reader.readAsDataURL(event.target.files[0]);
                        }
                      }}
                    />

                    {selectedImage && <img alt='background' src={selectedImage.image}
                      style={{
                        width: '150px',
                        height: '150px',
                        borderRadius: '25px',
                        objectFit: 'cover',
                        marginTop: '20px'
                      }}
                    />}
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6'>

                {categoriasPai && categoriasPai.length > 0 &&
                  <Fragment>
                    <h1>Ordenação</h1>

                    <div className='form-group row'>
                      {categoriasPai.map((field, index) => {
                        return (
                          <div className='col-sm-6 col-md-6' key={field.id_categoria_pai}>
                            <SmartConditionalInput
                              id={`ordenacao.${index}`}
                              label={field.nome}
                              placeholder='ordenação'
                              value={field.value}
                              onChange={(e) => changeOrdenacao(index, e.target.value)}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </Fragment>
                }

              </div>
              <div className='col-sm-12 col-md-12 mt-2'>
                <SmartCheckBox
                  label='Exibir nas preferências'
                  id='exibir_preferencias'
                  name='exibir_preferencias'
                />
              </div>
              <div className='col-sm-12 col-md-12 mt-2'>
                <SmartCheckBox
                  label='Manter fixo'
                  id='manter_fixo'
                  name='manter_fixo'
                />
              </div>

              <div className='col-sm-12 col-md-12 mt-2'>
                <SmartCheckBox
                  label='Ativo'
                  id='ativo'
                  name='ativo'
                />
              </div>

            </div>
          </SmartForm>

        </div>
      </div>
    </div>
  )
}

export default Novo
