import React from 'react'
import { useTranslation } from 'react-i18next'

function Item(props) {
  const { t } = useTranslation()

  console.log('Render: suporte - Item')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <dl className='dl-horizontal'>
            <dt>{t('lbl.ID')}</dt>
            <dd>{props.data.id_suporte}</dd>
            <dt>{t('lbl.motivo')}</dt>
            <dd>{props.data.motivo}</dd>
            <dt>{t('lbl.usuario')}</dt>
            <dd>{props.data.nome_usuario}</dd>
            <dt>{t('lbl.assunto')}</dt>
            <dd>{props.data.assunto}</dd>
            <dt>{t('lbl.descricao')}</dt>
            <dd>{props.data.descricao}</dd>


          </dl>
        </div>
      </div>
    </div>
  )
}

export default Item
