class TokenService {
  getLocalAccessToken() {
    const data = JSON.parse(localStorage.getItem('token'))
    return data
  }
  updateLocalAccessToken(token) {
    localStorage.setItem('token', JSON.stringify({ token }))
  }
  removeLocalAccessToken() {
    localStorage.removeItem('token')
  }
}

export default new TokenService()
