import React from 'react';
import { useSelector } from 'react-redux';

function PageHeader() {

  const title = useSelector((state) => state.pageHeader.title);
  const subTitle = useSelector((state) => state.pageHeader.subTitle);
  const description = useSelector((state) => state.pageHeader.description);
  const icon = useSelector((state) => state.pageHeader.icon);

  if (title === "")
    return null;

  console.log('Render: PageHeader - "' + title + ' ' + subTitle + '"');
  return <div className='subheader'>
    <h1 className='subheader-title'>
      <i className={'text-primary subheader-icon ' + icon}></i>
      {title}
      {subTitle ?
        <span className="fw-300 fs-i"> {subTitle}</span>
        :
        null
      }
      {description ?
        <small>{description}</small>
        : null}
    </h1>
  </div>
}

export default PageHeader;