import React from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory, useParams } from 'react-router-dom'
import { useHeader } from '../header'
import { SmartForm, SmartInput } from '../smartforms/controls'
import api, { useApi } from '../../services/api'
import { useEditarSchema } from './schema'

function Editar() {
  const { t } = useTranslation()
  const history = useHistory()
  const schema = useEditarSchema()
  let { id } = useParams()

  const { title } = useHeader(t('lbl.suporte'), t('lbl.editar'), 'fal fa-user') // informa os dados do cabeçalho interno
  const [ loading, data ] = useApi(`/suporte/detalhes/${id}`, title) // busca dados do registro

  if (loading) return <div>{t('txt.busca_registro')}</div>

  console.log('Render: suporte - Editar')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            config={{ defaultValues: data, resolver: yupResolver(schema) }}
            onSubmit={async data => {
              const header = t('txt.editar_registro', { $1: title })

              await api
                .put(`/suporte/${id}`, data)
                .then(response => {
                  NotificationManager.success(t('txt.registro_atualizado'), header, 2000)
                  history.push('../pesquisa')
                })
                .catch(err => {
                  if (err.response.status === 422) {
                    throw err.response.data.errors
                  } else {
                    NotificationManager.error(t('err.atualizar_registro'), header)
                  }
                })
            }}
          >
           
           <div className='row'>
              <div className='col-sm-12 col-md-6'>
                 <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='id_suporte'
                      label={t('lbl.id_suporte')}
                      name='id_suporte'
                      placeholder={t('lbl.id_suporte')}
                    />
                  </div>
                </div>
               
               
                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='id_motivo'
                      label={t('lbl.id_motivo')}
                      name='id_motivo'
                      placeholder={t('lbl.id_motivo')}
                      
                    />
                  </div>
                </div>


                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='id_usuario'
                      label={t('lbl.id_usuario')}
                      name='id_usuario'
                      placeholder={t('lbl.id_usuario')}
                      
                    />
                  </div>
                </div>


                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='assunto'
                      label={t('lbl.assunto')}
                      name='assunto'
                      placeholder={t('lbl.assunto')}
                      
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='descricao'
                      label={t('lbl.descricao')}
                      name='descricao'
                      placeholder={t('lbl.descricao')}
                      
                    />
                  </div>
                </div>
               
              </div>
            </div>
          </SmartForm>
        </div>
      </div>
    </div>
  )
}

export default Editar
