import React, { Fragment, useRef } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import AutoComplete from "react-google-autocomplete"
import { t } from 'i18next'
import Datetime from 'react-datetime'
import moment from 'moment'

import 'moment/locale/pt-br';

export const SmartCheckBox = ({
  label,
  name,
  register = () => { },
  errors = [],
  id,
  checked
}) => {
  console.log('Render: Checkbox - ' + label)
  return (
    <div
      className={name in errors ? 'checkbox-group has-error' : 'checkbox-group'}
    >
      <input
        {...register(name)}
        type='checkbox'
        className='field-checkbox'
        name={name}
        id={id || name}
        checked={checked}
      />
      <label htmlFor={id} className='label-checkbox'>
        {label}
      </label>

      {name in errors ? <small>{errors[name].message}</small> : null}
    </div>
  )
}

export const SmartConditionalInput = ({
  label,
  name,
  errors = [],
  password,
  id,
  value,
  placeholder,
  autoFocus,
  upperCase,
  onChange,
}) => {
  console.log('Render: Conditional Input - ' + label)

  return (
    <div className={name in errors ? 'field-group has-error' : 'field-group'}>
      <input
        autoComplete='off'
        type={password ? 'password' : 'text'}
        className='field-input'
        name={name}
        id={id || name}
        value={value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        style={upperCase ? { textTransform: 'uppercase' } : null}
        onChange={onChange}
      />

      <label htmlFor={id} className='field-label'>
        {label}
      </label>
      {name in errors ? (
        <small>{errors[name].message}</small>
      ) : (
        <small>&nbsp;</small>
      )}
    </div>
  )
}

export const SmartDatetime = ({
  label,
  name,
  id,
  control,
  errors = [],
  format = 'DD/MM/YYYY HH:mm:ss'
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {

        return (<>
          <label>{label}</label>
          <Datetime
            id={id}
            name={name}
            value={value ? moment(value).format(format) : null}
            dateFormat='DD/MM/YYYY'
            timeFormat='HH:mm'
            locale='pt-Br'
            onChange={(data) => {
              onChange(data)
            }}
          />
        </>
        )
      }}
    ></Controller>
  )
}

export const SmartInput = ({
  label,
  name,
  register = () => { },
  errors = [],
  password,
  id,
  value,
  placeholder,
  autoFocus,
  upperCase,
  onChange,
  disabled
}) => {
  console.log('Render: Input - ' + label)
  return (
    <div className={name in errors ? 'field-group has-error' : 'field-group'}>
      <input
        {...register(name)}
        autoComplete='off'
        type={password ? 'password' : 'text'}
        className={`field-input ${disabled ? 'disabled' : ''}`}
        name={name}
        id={id || name}
        value={value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        style={upperCase ? { textTransform: 'uppercase' } : null}
        onChange={onchange}
        disabled={disabled}
      />

      <label htmlFor={name} className='field-label'>
        {label}
      </label>
      {name in errors ? (
        <small>{errors[name].message}</small>
      ) : (
        <small>&nbsp;</small>
      )}
    </div>
  )
}

export const SmartTextarea = ({
  label,
  name,
  rows,
  register = () => { },
  errors = [],
  id,
  value,
  placeholder,
  autoFocus,
  upperCase
}) => {
  console.log('Render: Textarea - ' + label)
  return (
    <div className={name in errors ? 'field-group has-error' : 'field-group'}>
      <textarea
        {...register(name)}
        type='text'
        className='field-input'
        name={name}
        id={id || name}
        value={value}
        placeholder={placeholder}
        rows={rows}
        autoComplete='off'
        autoFocus={autoFocus}
        style={upperCase ? { textTransform: 'uppercase' } : null}
      />

      <label htmlFor={name} className='field-label'>
        {label}
      </label>
      {name in errors ? (
        <small>{errors[name].message}</small>
      ) : (
        <small>&nbsp;</small>
      )}
    </div>
  )
}

export const SmartSelect = ({
  label,
  name,
  control,
  errors = [],
  id,
  selectedValue,
  placeholder,
  noOptionsText,
  options,
  autoFocus,
  isClearable,
  onSelected,
  multiple = false

}) => {
  const customStyles = {

    menu: base => ({ ...base, zIndex: 9999 }),

    container: (base, state) => ({
      ...base,
      '& + .select-label': {
        color: errors[name]
          ? '#c80000 !important'
          : state.isFocused
            ? '#11998e !important'
            : ''
      }
    }),

    control: (base, state) => ({
      ...base,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,

      // custom gradient border
      border: state.isFocused
        ? 'double 1px transparent !important'
        : errors[name]
          ? 'double 1px #c80000 !important'
          : 0,
      backgroundImage:
        'linear-gradient(white, white), radial-gradient(circle at top left, #11998e, #38ef7d) !important',
      backgroundOrigin: 'border-box !important',
      backgroundClip: 'content-box, border-box !important',

      // minHeight
      // height: '33px',
      minHeight: '33px'
    }),

    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? '#886ab5' : '',
      '&:hover': {
        backgroundColor: '#0c7cd5',
        color: 'white'
      }
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      padding: 4,
      color: state.isFocused ? '#11998e' : 0
    }),

    noOptionsMessage: (base, state) => ({
      ...base,
      text: 'Nenhuma opção'
    })
  }

  console.log('Render: SmartSelect - ' + label)
  return (
    <div className='select-wrapper'>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {

          let mySelected = !multiple
            ? options.find(x => x.value === value || x.value === selectedValue)
            : options.filter(x => value?.includes(x.value))

          return (
            <Fragment>
              <Select
                menuPlacement='auto'
                isMulti={multiple}
                className={
                  name in errors ? 'smart-select select-required' : 'smart-select'
                }
                name={name}
                id={id}
                styles={customStyles}
                options={options}
                placeholder={placeholder}
                autoFocus={autoFocus}
                value={(mySelected)}
                isSearchable={true}
                isClearable={isClearable}
                onChange={val => {
                  if (val) {

                    const value = multiple ? val.map(x => x.value) : val.value
                    onChange(value)

                    if (onSelected)
                      onSelected(value)
                  }

                  else {
                    onChange(null)
                  }


                }}
              />
              <label className='select-label'>{label}</label>
            </Fragment>
          )
        }}
      ></Controller>
      {name in errors ? (
        <small>{errors[name]?.message || errors[name]?.value.message}</small>
      ) : (
        <small>&nbsp;</small>
      )}
    </div>
  )
}

export const SmartGooglePlace = ({
  label,
  name,
  id,
  control,
  errors = [],
  onSelected,
  types = ["establishment"]
}) => {
  return (
    <div className={name in errors ? 'field-group has-error' : 'field-group'}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {

          return (
            <Fragment>
              <AutoComplete
                defaultValue={value}
                name={name}
                id={id}
                className='field-input'
                apiKey='AIzaSyAStvcApt7GTMqYHAoNXV876Np8iNAR-AQ'
                onPlaceSelected={(place) => {

                  debugger
                  const country = place.address_components?.find(x => x.types.includes('country'))
                  const state = place.address_components?.find(x => x.types.includes('administrative_area_level_1'))
                  const city = place.address_components?.find(x => x.types.includes('administrative_area_level_2'))
                  const neighborhood = place.address_components?.find(x => x.types.includes('sublocality_level_1'))

                  const data = {
                    country: country?.long_name,
                    country_abbr: country?.short_name,

                    state: state?.long_name,
                    state_abbr: state?.short_name,

                    city: city?.long_name,
                    city_abbr: city?.short_name,

                    neighborhood: neighborhood?.long_name,
                    neighborhood_abbr: neighborhood?.short_name,

                    postal_code: place.address_components?.find(x => x.types.includes('postal_code'))?.long_name,

                    address: place.address_components?.find(x => x.types.includes('route'))?.short_name,
                    street_number: place.address_components?.find(x => x.types.includes('street_number'))?.long_name,

                    formatted_address: place.formatted_address,
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    place_id: place.place_id
                  }

                  onChange(data)
                  if (onSelected)
                    onSelected(data)

                }}
                language={['pt-BR']}
                style={{ width: '100%', }}
                options={{
                  types: types
                }}
              />
              <label htmlFor={name} className='field-label'>{label}</label>
            </Fragment>
          )
        }}
      ></Controller>
      {name in errors ? (
        <small>{errors[name]?.message || errors[name]?.value.message}</small>
      ) : (
        <small>&nbsp;</small>
      )}
    </div>
  )
}

export const SmartForm = ({ config, children, onSubmit,
  isDirty = false,
  goBack = true,
  customButtons,
  submitButtonText = t('lbl.salvar'),
  submitButtonIcon = 'fal fa-save'
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const methods = useForm(config)
  const { handleSubmit } = methods

  const recursiveMap = children => {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) return child

      if (child.props.children) {
        child = React.cloneElement(child, {
          children: recursiveMap(child.props.children)
        })
      }

      if (child.props.name) {
        if (child.type.name === 'SmartSelect' ||
          child.type.name === 'SmartGooglePlace' ||
          child.type.name === 'SmartDatetime') {
          return React.createElement(child.type, {
            ...{
              ...child.props,
              control: methods.control,
              errors: methods.formState.errors,
              key: child.props.name
            }
          })
        } else {
          return React.createElement(child.type, {
            ...{
              ...child.props,
              register: methods.register,
              errors: methods.formState.errors,
              key: child.props.name
            }
          })
        }
      }

      return child
    })
  }

  console.log('Render: SmartForm')
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={e => {
          console.log(e)
          handleSubmit(onSubmit)(e).catch(e => {
            e.forEach(el => {
              if (el.field.includes('body.')) {
                methods.setError(el.field.replace('body.', ''), {
                  type: 'Server Side',
                  message: el.message
                })
              }
              else {
                methods.setError(el.field, {
                  type: 'Front End',
                  message: el.message
                })
              }
            })
          })
        }}
      >
        {typeof children == 'function'
          ? children({
            isSubmitting: methods.formState.isSubmitting,
            isValidating: methods.formState.isValidating
          })
          : recursiveMap(children)}

        <div className='page-footer'>
          <div className='row no-gutters'>
            <div className='col-sm-12 col-md-6'></div>
            <div className='col-sm-12 col-md-6 text-right'>
              {goBack &&
                <button
                  type='button'
                  className='btn btn-info'
                  onClick={history.goBack}
                >
                  {t('lbl.voltar')}
                </button>}
              {customButtons && customButtons(methods)}
              <button
                type='submit'
                className='btn btn-primary'
                disabled={
                  isDirty
                    ? false
                    : methods.formState.isSubmitting ||
                    methods.formState.isValidating ||
                    !methods.formState.isDirty
                }
              >
                <i className={submitButtonIcon}></i>
                {methods.formState.isSubmitting
                  ? t('lbl.enviando_')
                  : submitButtonText}
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
