import React from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory, useParams } from 'react-router-dom'
import { useHeader } from '../header'
import { SmartForm, SmartInput } from '../smartforms/controls'
import api, { useApi } from '../../services/api'
import { useEditarSchema } from './schema'

function Editar() {
  const { t } = useTranslation()
  const history = useHistory()
  const schema = useEditarSchema()
  let { id } = useParams()

  const { title } = useHeader(t('lbl.operador'), t('lbl.editar'), 'fal fa-user') // informa os dados do cabeçalho interno
  const [ loading, data ] = useApi(`/operador/detalhes/${id}`, title) // busca dados do registro

  if (loading) return <div>{t('txt.busca_registro')}</div>

  console.log('Render: operador - Editar')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            config={{ defaultValues: data, resolver: yupResolver(schema) }}
            onSubmit={async data => {
              const header = t('txt.editar_registro', { $1: title })

              await api
                .put(`/operador/${id}`, data)
                .then(response => {
                  NotificationManager.success(t('txt.registro_atualizado'), header, 2000)
                  history.push('../pesquisa')
                })
                .catch(err => {
                  if (err.response.status === 422) {
                    throw err.response.data.errors
                  } else {
                    NotificationManager.error(t('err.atualizar_registro'), header)
                  }
                })
            }}
          >
           
              <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='nome'
                      label={t('lbl.nome')}
                      name='nome'
                      placeholder={t('lbl.nome')}
                    />
                  </div>
                </div>

              <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='sobrenome'
                      label={t('lbl.sobrenome')}
                      name='sobrenome'
                      placeholder={t('lbl.sobrenome')}
                      autoFocus
                    />
                  </div>
              </div>
              <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='email_id_operador'
                      label={t('lbl.email')}
                      name='email_id_operador'
                      placeholder={t('lbl.email')}
                      autoFocus
                    />
                  </div>
              </div>
           
            
          </SmartForm>
        </div>
      </div>
    </div>
  )
}

export default Editar
