import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHeader } from '../header'
import SmartTable from '../smartforms/table'
import { useApi } from '../../services/api'

function Index() {
  const history = useHistory()
  const { t } = useTranslation()

  const { title } = useHeader(t('lbl.micro_regiao'), t('lbl.pesquisa'), 'fal fa-map') // informa os dados do cabeçalho interno
  const [loading, data] = useApi(`/microregiao/pesquisa`, title) // busca todos os registros

  // Colunas para exibir na tabela
  const columns = [

    {
      Header: t('lbl.nome'),
      accessor: 'nome',
      style: {
        width: 450,
        minWidth: 200,
      }
    },

    {
      Header: t('lbl.estado'),
      accessor: 'estado',
      style: {
        width: 450,
        minWidth: 200,
      }
    },
  ]

  if (loading) return <div>{t('txt.busca_registros')}</div>

  console.log('Render: microregiao - Pesquisa')
  return (
    <Fragment>
      <button
        className='btn btn-primary btn-header-new'
        onClick={() => history.push('./novo')}
      >
        {t('lbl.novo')}
      </button>

      <div className='panel'>
        <div className='panel-container'>
          <div className='panel-content'>
            <SmartTable
              columns={columns}
              data={data}
              route='/microregiao'
              propID='id_micro_regiao'
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Index
