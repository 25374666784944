import React, { Suspense } from 'react';
import { Provider } from 'react-redux';

import AppStore from './stores/app';

import './styles/main.css';
import 'react-notifications/lib/notifications.css';

import AppManager from './components/appManager.js';

import './locale' // conecta ao backend em busca das tradução i18next

function App() {

  console.log('Carregando Aplicação...');
  return <Suspense fallback='loading...'>
    <div className='App'>
      <Provider store={AppStore}>
        <AppManager />
      </Provider >
    </div >
  </Suspense>

}

export default App;
