import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useHeader } from '../header'
import SmartTable from '../smartforms/table'
import { useApi } from '../../services/api'

function Index() {
  const history = useHistory()
  const { t } = useTranslation()

  const { title } = useHeader(t('lbl.operador'), t('lbl.pesquisa'), 'fal fa-user') // informa os dados do cabeçalho interno
  const [ loading, data ] = useApi(`/operador/pesquisa`, title) // busca todos os registros

  // Colunas para exibir na tabela
  const columns = [
  {
      Header: t('lbl.nome'),
      accessor: 'nome',
      style: {
        width: 450,
        minWidth: 50,
      }
    },

    {
      Header: t('lbl.sobrenome'),
      accessor: 'sobrenome',
      style: {
        width: 450,
        minWidth: 50,
      }
    },
    {
      Header: t('lbl.email'),
      accessor: 'email_id_operador',
      style: {
        width: 450,
        minWidth: 50,
      }
    },
   
   

    // {
    
    //   Header: t('lbl.excluido'),
    //   accessor: d => {
    //     if (d.data_excluido) {
    //       return moment(d.data_excluido)
    //         .local()
    //         .format('DD-MM-YYYY HH:mm:ss')
    //     }

    //     return null
    //   },
    //   style: {
    //     width: 80,
    //     minWidth: 80
    //   }
    // }
  ]

  if (loading) return <div>{t('txt.busca_registros')}</div>

  console.log('Render: operador - Pesquisa')
  return (
    <Fragment>
      <button
        className='btn btn-primary btn-header-new'
        onClick={() => history.push('./novo')}
      >
        {t('lbl.novo')}
      </button>

      <div className='panel'>
        <div className='panel-container'>
          <div className='panel-content'>
            <SmartTable
              columns={columns}
              data={data}
              route='/operador'
              propID='id_operador'
            ></SmartTable>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Index
