import React from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { useHeader } from '../header'
import { SmartForm, SmartInput } from '../smartforms/controls'
import api, { useApi } from '../../services/api'

function Editar() {
  const { t } = useTranslation()

  const { title } = useHeader(t('lbl.minha_conta'), '', 'fal fa-user') // informa os dados do cabeçalho interno
  const [loading, data] = useApi(`/minhaconta/detalhes`, title) // busca dados do registro

  if (loading) return <div>{t('txt.busca_registro')}</div>

  console.log('Render: bairro - Editar')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            config={{ defaultValues: data }}
            onSubmit={async data => {
              const header = t('txt.editar_registro', { $1: title })

              if (data.senha !== data.confirma_senha) {
                const errors = [{
                  field: 'confirma_senha',
                  message: 'Senhas não são iguais'
                }]
                throw errors
              }

              await api
                .put(`/minhaconta/atualizar`, data)
                .then(response => {
                  NotificationManager.success(t('txt.registro_atualizado'), header, 2000)
                })
                .catch(err => {
                  if (err.response.status === 422) {
                    throw err.response.data.errors
                  } else {
                    NotificationManager.error(t('err.atualizar_registro'), header)
                  }
                })
            }}
          >
            <div className='row'>
              <div className='col-sm-12 col-md-6'>

                <div className='form-group row' style={{ marginTop: '20px' }}>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='nome'
                      label={t('lbl.nome')}
                      name='nome'
                      placeholder={t('lbl.nome')}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='email'
                      label={t('lbl.email')}
                      name='email'
                      placeholder={t('lbl.email')}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-6 col-md-6'>
                    <SmartInput
                      id='senha'
                      label={t('lbl.senha')}
                      name='senha'
                      placeholder={t('lbl.senha')}
                      password={true}
                    />
                  </div>
                  <div className='col-sm-6 col-md-6'>
                    <SmartInput
                      id='confirma_senha'
                      label={t('lbl.confirmar_senha')}
                      name='confirma_senha'
                      placeholder={t('lbl.confirmar_senha')}
                      password={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </SmartForm>
        </div>
      </div>
    </div>
  )
}

export default Editar
