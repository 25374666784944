import { createSlice } from '@reduxjs/toolkit'

export const pageHeaderSlice = createSlice({

  name: 'pageHeader',

  initialState: {
    title: '',
    subTitle: '',
    description: '',
    icon: '',
  },

  reducers: {

    setTitle: (state, action) => {

      state.title = action.payload.title;
      state.subTitle = action.payload.subTitle;
      state.description = action.payload.description;
      state.icon = action.payload.icon;

    },

  },
})

export const { setTitle } = pageHeaderSlice.actions

export default pageHeaderSlice.reducer