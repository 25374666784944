import React from 'react'
import { useTranslation } from 'react-i18next'

function Item(props) {
  const { t } = useTranslation()

  console.log('Render: Micro Região - Item')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <dl className='dl-horizontal'>
            <dt>{t('lbl.nome')}</dt>
            <dd>{props.data.nome}</dd>
            <dt>{t('lbl.estado')}</dt>
            <dd>{props.data.estado}</dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Item
