import React, { useState, Fragment, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import api, { useApi } from '../../services/api'
import { useHistory } from 'react-router-dom'
import { useHeader } from '../header'
import useSchema from './schema'
import { SmartForm, SmartGooglePlace, SmartInput, SmartSelect, SmartConditionalInput, SmartTextarea, SmartDatetime } from '../smartforms/controls'
import { Carousel } from 'react-responsive-carousel'

import "react-responsive-carousel/lib/styles/carousel.min.css"
import "react-datetime/css/react-datetime.css"
import moment from 'moment'
import isUrlHttp from 'is-url-http'


function Novo() {
  const { t } = useTranslation()
  const history = useHistory()
  const schema = useSchema()

  const photosRef = useRef()

  const { title } = useHeader(t('lbl.local'), t('lbl.novo'), 'fal fa-user') // informa os dados do cabeçalho interno
  const [categoriasloading, categorias] = useApi('/categoria/pesquisa', title)
  const [perfilListaLoading, perfilLista] = useApi('/usuario/lista', title)
  const [estadosloading, estados] = useApi('/estado/pesquisa', title)
  const [cidadesloading, cidades] = useApi('/cidade/pesquisa', title)
  const [bairrosloading, bairros] = useApi('/bairro/pesquisa', title)
  const [microregioesloading, microregioes] = useApi('/microregiao/pesquisa', title)

  const [selectedImage, setSelectedImage] = useState()
  const [categoriasSelecionadas, setCategoriasSelecionadas] = useState([])
  const [estadosSelecionados, setEstadosSelecionados] = useState([])
  const [cidadesSelecionadas, setCidadesSelecionadas] = useState([])
  const [bairrosSelecionados, setBairrosSelecionados] = useState([])
  const [microRegioesSelecionadas, setMicroRegioesSelecionadas] = useState([])

  const [ordemEstados, setOrdemEstados] = useState([])
  const [ordemCidades, setOrdemCidades] = useState([])
  const [ordemBairros, setOrdemBairros] = useState([])
  const [ordemMicroRegiao, setOrdemMicroRegiao] = useState([])

  const [id_cidade, setIDCidade] = useState(null)
  const [id_bairro, setIDBairro] = useState(null)
  const [enderecoGoogle, setEnderecoGoogle] = useState(null)

  const [photos, setPhotos] = useState([])
  const [isDirty, setIsDirty] = useState(false)

  const [linkValue, setLinkValue] = useState()
  const [links, setLinks] = useState([])

  const changeEstadoOrdem = async (id, value, id_categoria) => {
    const ordemEstadosLista = [...ordemEstados]
    const item = ordemEstadosLista.find(e => e.id_estado === id && e.id_categoria === id_categoria)
    if (item)
      item.ordem = value

    else
      ordemEstadosLista.push({ id_estado: id, ordem: value, id_categoria: id_categoria })

    setOrdemEstados(ordemEstadosLista)
    setIsDirty(true)
  }

  const changeCidadeOrdem = async (id, value, id_categoria) => {
    const ordemCidadesLista = [...ordemCidades]
    const item = ordemCidadesLista.find(c => c.id_cidade === id && c.id_categoria === id_categoria)
    if (item)
      item.ordem = value

    else
      ordemCidadesLista.push({ id_cidade: id, ordem: value, id_categoria: id_categoria })

    setOrdemCidades(ordemCidadesLista)
    setIsDirty(true)
  }

  const changeBairroOrdem = async (id, value, id_categoria) => {
    const ordemBairrosLista = [...ordemBairros]
    const item = ordemBairrosLista.find(c => c.id_bairro === id && c.id_categoria === id_categoria)
    if (item)
      item.ordem = value

    else
      ordemBairrosLista.push({ id_bairro: id, ordem: value, id_categoria: id_categoria })

    setOrdemBairros(ordemBairrosLista)
    setIsDirty(true)
  }

  const changeMicroOrdem = async (id, value, id_categoria) => {
    const ordemMicroLista = [...ordemMicroRegiao]
    const item = ordemMicroLista.find(c => c.id_micro_regiao === id && c.id_categoria === id_categoria)
    if (item)
      item.ordem = value

    else
      ordemMicroLista.push({ id_micro_regiao: id, ordem: value, id_categoria: id_categoria })

    setOrdemMicroRegiao(ordemMicroLista)
    setIsDirty(true)
  }

  const getPhotos = async (place_id) => {

    api.get(`/local/fotos/${place_id}`)
      .then(res => {
        if (res.data && res.data?.length > 0)
          setSelectedImage({ image: res.data[0], url: true })
        // setPhotos(res.data.slice(1).map(x => {
        //   return { image: x, url: true }
        // }))
      })

  }

  useEffect(() => {

    if (enderecoGoogle && cidades) {
      setIDCidade(cidades.find(x => x.nome === enderecoGoogle.city + ' - ' + enderecoGoogle.state_abbr)?.id_cidade)
    }

  }, [enderecoGoogle, cidades])

  useEffect(() => {

    if (enderecoGoogle && bairros) {
      setIDBairro(bairros.find(x => x.nome === enderecoGoogle.neighborhood + ' - ' + enderecoGoogle.city + '/' + enderecoGoogle.state_abbr)?.id_cidade)
    }

  }, [enderecoGoogle, bairros])

  console.log('Render: local - Novo')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            customButtons={(methods) => {
              return (
                <button
                  type='submit'
                  className='btn btn-warning'
                  disabled={
                    isDirty
                      ? false
                      : methods.formState.isSubmitting ||
                      methods.formState.isValidating ||
                      !methods.formState.isDirty
                  }
                  onClick={() => {
                    methods.setValue('pendente', true)
                  }}
                >
                  <i className='fal fa-plus'></i>
                  {methods.formState.isSubmitting
                    ? t('lbl.enviando_')
                    : 'Salvar pendente'}
                </button>
              )
            }}
            isDirty={isDirty}
            config={{
              resolver: yupResolver(schema),
            }}
            onSubmit={async data => {
              const header = t('txt.novo_registro', { $1: title })

              if (categoriasSelecionadas?.length > 0 && (
                (!estadosSelecionados || estadosSelecionados.length === 0) &&
                (!cidadesSelecionadas || cidadesSelecionadas.length === 0) &&
                (!bairrosSelecionados || bairrosSelecionados.length === 0) &&
                (!microRegioesSelecionadas || microRegioesSelecionadas.length === 0)
              )) {
                return NotificationManager.error('Selecione pelo menos um estado|cidade|bairro|micro região de destaque', header, 2000)
              }

              var formData = new FormData()
              formData.append('nome', data.nome)

              if (data.periodo_inicio && moment(data.periodo_inicio).isValid) {
                formData.append('periodo_inicio', data.periodo_inicio)
              }

              if (data.periodo_fim && moment(data.periodo_fim).isValid) {
                formData.append('periodo_fim', data.periodo_fim)
              }

              if (data.pendente === true)
                formData.append('pendente', true)

              else if (data.pendente === false)
                formData.append('pendente', false)

              formData.append('endereco', data.endereco)
              if (data.tags) {
                formData.append('tags', data.tags)
              }
              formData.append('descricao', data.descricao)
              formData.append('funcionamento', data.funcionamento)
              formData.append('info_adicional', data.info_adicional)
              formData.append('telefone', data.telefone)
              formData.append('contato', data.contato)
              formData.append('id_cidade', id_cidade || data.id_cidade)

              if (data.id_perfil)
                formData.append('id_perfil', data.id_perfil)

              if (data.id_bairro)
                formData.append('id_bairro', data.id_bairro)

              if (data.localizacao && data.localizacao.formatted_address) {
                formData.append('localizacao[endereco]', data.localizacao.formatted_address)

                formData.append('localizacao[cidade]', data.localizacao.city)
                formData.append('localizacao[cidade_sigla]', data.localizacao.city_abbr)

                formData.append('localizacao[estado]', data.localizacao.state)
                formData.append('localizacao[estado_sigla]', data.localizacao.state_abbr)

                formData.append('localizacao[pais]', data.localizacao.country)
                formData.append('localizacao[pais_sigla]', data.localizacao.country_abbr)

                formData.append('localizacao[postal_code]', data.localizacao.postal_code)

                formData.append('localizacao[google_place_id]', data.localizacao.place_id)
                formData.append('localizacao[latitude]', data.localizacao.lat)
                formData.append('localizacao[longitude]', data.localizacao.lng)
              }

              if (links.length > 0) {
                for (const index in links) {
                  formData.append(`links[${index}]`, links[index])
                }
              }

              if (categoriasSelecionadas?.length > 0) {

                var idx_estado = 0
                var idx_cidade = 0
                var idx_bairro = 0
                var idx_micro = 0

                for (const index in categoriasSelecionadas) {
                  formData.append(`Categorias[${index}]`, categoriasSelecionadas[index])

                  const id_categoria = categoriasSelecionadas[index]

                  for (var id_estado of estadosSelecionados) {
                    let ordem = ordemEstados?.find(x => x.id_categoria === id_categoria && x.id_estado === id_estado)
                    formData.append(`Estados[${idx_estado}][id_categoria]`, id_categoria)
                    formData.append(`Estados[${idx_estado}][id_estado]`, id_estado)
                    formData.append(`Estados[${idx_estado}][ordem]`, ordem?.ordem || '')
                    idx_estado++;
                  }

                  for (var id_cid of cidadesSelecionadas) {
                    let ordem = ordemCidades?.find(x => x.id_categoria === id_categoria && x.id_cidade === id_cid)
                    formData.append(`Cidades[${idx_cidade}][id_categoria]`, id_categoria)
                    formData.append(`Cidades[${idx_cidade}][id_cidade]`, id_cid)
                    formData.append(`Cidades[${idx_cidade}][ordem]`, ordem?.ordem || '')
                    idx_cidade++;
                  }

                  for (var id_bairro of bairrosSelecionados) {
                    let ordem = ordemBairros?.find(x => x.id_categoria === id_categoria && x.id_bairro === id_bairro)
                    formData.append(`Bairros[${idx_bairro}][id_categoria]`, id_categoria)
                    formData.append(`Bairros[${idx_bairro}][id_bairro]`, id_bairro)
                    formData.append(`Bairros[${idx_bairro}][ordem]`, ordem?.ordem || '')
                    idx_bairro++;
                  }

                  for (var id_micro_regiao of microRegioesSelecionadas) {
                    let ordem = ordemMicroRegiao?.find(x => x.id_categoria === id_categoria && x.id_micro_regiao === id_micro_regiao)
                    formData.append(`MicroRegioes[${idx_micro}][id_micro_regiao]`, id_micro_regiao)
                    formData.append(`MicroRegioes[${idx_micro}][ordem]`, ordem?.ordem || '')
                    formData.append(`MicroRegioes[${idx_micro}][id_categoria]`, id_categoria)
                    idx_micro++;
                  }

                }
              }

              if (selectedImage) {
                if (selectedImage.file)
                  formData.append('media', selectedImage.file)

                else if (selectedImage.url)
                  formData.append('image_url', selectedImage.image)
              }

              if (photos) {
                for (var x = 0; x < photos.length; x++) {
                  if (photos[x].file)
                    formData.append(`files[${x}]`, photos[x].file)
                }
              }

              await api
                .post(`/local/novo`, formData, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(response => {
                  NotificationManager.success(t('txt.registro_salvo'), header, 2000) // exibe alerta de sucesso
                  history.push('./pesquisa') // volta pra tela de pesquisa
                })
                .catch(err => {
                  console.log(err)

                  // erro de validação
                  if (err.response?.status === 422) {
                    throw err.response.data.errors
                  }

                  // outro tipo de erro
                  else {
                    NotificationManager.error(t('err.salvar_registro'), header) // exibe alerta padrão
                  }
                })
            }}
          >
            <div className='row'>
              <div className='col-sm-12 col-md-6'>

                <div className='form-group row'>
                  <div className='col-sm-6 col-md-6'>
                    <SmartDatetime
                      label='Período início'
                      name='periodo_inicio'
                    />
                  </div>
                  <div className='col-sm-6 col-md-6'>
                    <SmartDatetime
                      label='Período fim'
                      name='periodo_fim'
                    />
                  </div>
                </div>

                <br />
                <div className='form-group row '>
                  <div className='col-sm-12 col-md-12'>
                    <SmartSelect
                      id='CategoriasSelecionadas'
                      label={t('lbl.categorias')}
                      name='CategoriasSelecionadas'
                      placeholder={t('lbl.selecione')}
                      multiple={true}
                      onSelected={(items) => {
                        const removidos = categoriasSelecionadas?.filter(c => !items.includes(c))
                        if (removidos?.length > 0) {
                          setOrdemEstados(ordemEstados.filter(e => !removidos.includes(e.id_categoria)))
                          setOrdemCidades(ordemCidades.filter(e => !removidos.includes(e.id_categoria)))
                          setOrdemBairros(ordemBairros.filter(e => !removidos.includes(e.id_categoria)))
                          setOrdemMicroRegiao(ordemMicroRegiao.filter(e => !removidos.includes(e.id_categoria)))
                        }

                        setCategoriasSelecionadas(items)
                      }}
                      options={!categoriasloading && categorias
                        ? categorias.map(item => {
                          return {
                            value: item.id_categoria,
                            label: item.nome
                          }
                        }).sort((a, b) => { return a.label.localeCompare(b.label) })
                        : []} />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartSelect
                      id='id_perfil'
                      label={t('lbl.perfil')}
                      name='id_perfil'
                      placeholder={t('lbl.selecione')}
                      options={!perfilListaLoading && perfilLista
                        ? perfilLista.map(item => {
                          return {
                            value: item.id,
                            label: item.nome
                          }
                        }).sort((a, b) => { return a.label.localeCompare(b.label) })
                        : []}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>

                    <SmartInput
                      id='nome'
                      label={t('lbl.nome')}
                      name='nome'
                      placeholder={t('lbl.nome')}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartGooglePlace
                      id="localizacao"
                      name="localizacao"
                      label="Selecione o local"
                      onSelected={(data) => {
                        setEnderecoGoogle(data)
                        getPhotos(data.place_id)
                      }}
                      types={[]}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-6 col-md-6'>
                    <SmartSelect
                      id='id_cidade'
                      label={t('lbl.cidade')}
                      name='id_cidade'
                      selectedValue={id_cidade}
                      placeholder={t('lbl.selecione')}
                      options={!cidadesloading && cidades
                        ? cidades.map(item => {
                          return {
                            value: item.id_cidade,
                            label: item.nome
                          }
                        }).sort((a, b) => { return a.label.localeCompare(b.label) })
                        : []} />
                  </div>
                  <div className='col-sm-6 col-md-6'>
                    <SmartSelect
                      id='id_bairro'
                      label={t('lbl.bairro')}
                      name='id_bairro'
                      selectedValue={id_bairro}
                      placeholder={t('lbl.selecione')}
                      options={!bairrosloading && bairros
                        ? bairros.map(item => {
                          return {
                            value: item.id_bairro,
                            label: item.nome_completo
                          }
                        }).sort((a, b) => { return a.label.localeCompare(b.label) })
                        : []} />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <label className='field-label'>Adicionar uma foto do local</label>
                    <br />
                    <input
                      ref={photosRef}
                      type="file"
                      id="fotos"
                      style={{ marginTop: 10, marginBottom: 25 }}
                      onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                          if (event.target.files[0].size > 700 * 1024) {
                            return alert('Limite máximo de upload 700KB')
                          }

                          setIsDirty(true)
                          let reader = new FileReader()
                          reader.onload = (e) => {
                            setPhotos([...photos, {
                              image: e.target.result,
                              file: event.target.files[0]
                            }])
                            photosRef.current.value = ""
                          }
                          reader.readAsDataURL(event.target.files[0])
                        }
                      }}
                    />

                    {photos && photos.length > 0 &&
                      <Carousel width='50%'>
                        {photos.map(x => {
                          return <div>
                            <img src={x.image} alt="carousel" />
                            <button type='button' onClick={() => {
                              const items = [...photos.filter(p => p.image !== x.image)]
                              setPhotos(items)
                            }} className='legend'>{t('lbl.excluir')}</button>
                          </div>
                        })}
                      </Carousel>
                    }
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='tags'
                      label={t('lbl.tags')}
                      name='tags'
                      placeholder={t('lbl.tags')}
                    />
                    <label>{t('txt.tags_aviso')}</label>
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>

                    <SmartTextarea
                      id='descricao'
                      label={t('lbl.descricao')}
                      name='descricao'
                      placeholder={t('lbl.descricao')}
                      rows={4}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>

                    <SmartTextarea
                      id='funcionamento'
                      label={t('lbl.funcionamento')}
                      name='funcionamento'
                      placeholder={t('lbl.funcionamento')}
                      rows={4}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>

                    <SmartTextarea
                      id='info_adicional'
                      label={t('lbl.info_adicional')}
                      name='info_adicional'
                      placeholder={t('lbl.info_adicional')}
                      rows={4}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-10 col-md-10'>
                    <SmartConditionalInput
                      id='link'
                      label='Link'
                      name='link'
                      value={linkValue}
                      placeholder='Adicionar link'
                      onChange={(e) => {
                        setLinkValue(e.target.value)
                      }}
                    />
                  </div>
                  <div className='col-sm-2 col-md-2'>
                    <button
                      type='button'
                      style={{
                        marginTop: 25
                      }}
                      onClick={(e) => {
                        if (!isUrlHttp(linkValue)) {
                          return alert('Link não é uma url válida')
                        }
                        if (links.includes(linkValue)) {
                          return alert('Link já foi adicionado')
                        }
                        setLinkValue('')
                        setLinks([...links, linkValue])
                      }}
                    >Adicionar</button>
                  </div>
                  {links &&
                    <ul>
                      {links.map(x => {
                        return <li style={{ marginBottom: 10 }}>{x} <button type='button' onClick={() => { setLinks(links.filter(l => l !== x)) }}>remover</button></li>
                      })}
                    </ul>
                  }

                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>

                    <SmartInput
                      id='telefone'
                      label={t('lbl.telefone')}
                      name='telefone'
                      placeholder={t('lbl.telefone')}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>

                    <SmartInput
                      id='contato'
                      label={t('lbl.contato')}
                      name='contato'
                      placeholder={t('lbl.contato')}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>

                    <SmartInput
                      id='endereco'
                      label={t('lbl.endereco')}
                      name='endereco'
                      placeholder={t('lbl.endereco')}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-6 col-md-6'>
                    <label className='field-label'>Foto</label>
                    <br />
                    <input
                      type="file"
                      id="media"
                      style={{ marginTop: '10px' }}
                      onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                          if (event.target.files[0].size > 700 * 1024) {
                            return alert('Limite máximo de upload 700KB')
                          }

                          setIsDirty(true)
                          let reader = new FileReader()
                          reader.onload = (e) => {
                            setSelectedImage({
                              image: e.target.result,
                              file: event.target.files[0]
                            })
                          }
                          reader.readAsDataURL(event.target.files[0]);
                        }
                      }}
                    />

                    {selectedImage && <img alt='background' src={selectedImage.image}
                      style={{
                        width: '150px',
                        height: '150px',
                        borderRadius: '25px',
                        objectFit: 'cover',
                        marginTop: '20px'
                      }}
                    />}
                  </div>
                </div>

              </div>
              <div className='col-sm-12 col-md-6'>

                <h1 style={{ marginTop: '20px', marginBottom: '20px' }}>Destaques</h1>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>

                    <SmartSelect
                      id='EstadosSelecionados'
                      label={t('lbl.estado')}
                      name='EstadosSelecionados'
                      placeholder={t('lbl.selecione')}
                      multiple={true}
                      onSelected={(items) => { setEstadosSelecionados(items) }}
                      options={!estadosloading && estados
                        ? estados.map(item => {
                          return {
                            value: item.id_estado,
                            label: item.nome
                          }
                        }).sort((a, b) => { return a.label.localeCompare(b.label) })
                        : []} />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>

                    <SmartSelect
                      id='CidadesSelecionadas'
                      label={t('lbl.cidade')}
                      name='CidadesSelecionadas'
                      placeholder={t('lbl.selecione')}
                      multiple={true}
                      onSelected={(items) => { setCidadesSelecionadas(items) }}
                      options={!cidadesloading && cidades
                        ? cidades.map(item => {
                          return {
                            value: item.id_cidade,
                            label: item.nome
                          }
                        }).sort((a, b) => { return a.label.localeCompare(b.label) })
                        : []} />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>

                    <SmartSelect
                      id='BairrosSelecionados'
                      label={t('lbl.bairro')}
                      name='BairrosSelecionados'
                      placeholder={t('lbl.selecione')}
                      multiple={true}
                      onSelected={(items) => { setBairrosSelecionados(items) }}
                      options={!bairrosloading && bairros
                        ? bairros.map(item => {
                          return {
                            value: item.id_bairro,
                            label: item.nome_completo
                          }
                        }).sort((a, b) => { return a.label.localeCompare(b.label) })
                        : []} />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>

                    <SmartSelect
                      id='MicroRegioesSelecionadas'
                      label={t('lbl.micro_regiao')}
                      name='MicroRegioesSelecionadas'
                      placeholder={t('lbl.selecione')}
                      multiple={true}
                      onSelected={(items) => { setMicroRegioesSelecionadas(items) }}
                      options={!microregioesloading && microregioes
                        ? microregioes.map(item => {
                          return {
                            value: item.id_micro_regiao,
                            label: item.nome_completo
                          }
                        }).sort((a, b) => { return a.label.localeCompare(b.label) })
                        : []} />
                  </div>
                </div>

                {categorias?.length > 0 &&
                  categoriasSelecionadas?.length > 0 &&
                  categoriasSelecionadas.map(id_categoria => {

                    var categoria = categorias.find(x => x.id_categoria === id_categoria)

                    return <Fragment>

                      {(estadosSelecionados?.length > 0 ||
                        cidadesSelecionadas?.length > 0 ||
                        bairrosSelecionados?.length > 0 ||
                        microRegioesSelecionadas?.length > 0) &&
                        <h1>Ordem {categoria.nome}</h1>}

                      <div className='form-group row'>
                        {estados?.length > 0 &&
                          estadosSelecionados?.length > 0 &&
                          estadosSelecionados.map(id_estado => {

                            var estado = estados.find(e => e.id_estado === id_estado)
                            return (
                              <div className='col-sm-6 col-md-6'
                                key={`ordem.${categoria.id_categoria}.estado.${id_estado}`}
                              >
                                <SmartConditionalInput
                                  id={`ordem.${categoria.id_categoria}.estado.${id_estado}`}
                                  label={estado.nome}
                                  placeholder='ordenação'
                                  value={ordemEstados.find(x => x.id_estado === id_estado && x.id_categoria === id_categoria)?.ordem}
                                  onChange={(e) => changeEstadoOrdem(estado.id_estado, e.target.value, categoria.id_categoria)}
                                />
                              </div>
                            )

                          })}
                      </div>

                      <div className='form-group row'>
                        {cidades?.length > 0 &&
                          cidadesSelecionadas.length > 0 &&
                          cidadesSelecionadas.map(id_cidade => {

                            var cidade = cidades.find(x => x.id_cidade === id_cidade)
                            return (
                              <div className='col-sm-6 col-md-6'
                                key={`ordem.${categoria.id_categoria}.cidade.${id_cidade}`}
                              >
                                <SmartConditionalInput
                                  id={`ordem.${categoria.id_categoria}.cidade.${id_cidade}`}
                                  label={cidade.nome}
                                  placeholder='ordenação'
                                  value={ordemCidades.find(x => x.id_cidade === id_cidade && x.id_categoria === id_categoria)?.ordem}
                                  onChange={(e) => changeCidadeOrdem(cidade.id_cidade, e.target.value, categoria.id_categoria)}
                                />
                              </div>
                            )
                          })}
                      </div>

                      <div className='form-group row'>
                        {bairros?.length > 0 &&
                          bairrosSelecionados?.length > 0 &&
                          bairrosSelecionados.map(id_bairro => {

                            var bairro = bairros.find(x => x.id_bairro === id_bairro)
                            return (
                              <div className='col-sm-6 col-md-6' key={`ordem.${categoria.id_categoria}.bairro.${id_bairro}`}>
                                <SmartConditionalInput
                                  id={`ordem.${categoria.id_categoria}.bairro.${id_bairro}`}
                                  label={bairro.nome}
                                  placeholder='ordenação'
                                  value={ordemBairros.find(x => x.id_bairro === id_bairro && x.id_categoria === id_categoria)?.ordem}
                                  onChange={(e) => changeBairroOrdem(bairro.id_bairro, e.target.value, categoria.id_categoria)}
                                />
                              </div>
                            )
                          })}
                      </div>

                      <div className='form-group row'>
                        {microregioes?.length > 0 &&
                          microRegioesSelecionadas?.length > 0 &&
                          microRegioesSelecionadas.map(id_micro => {

                            var micro = microregioes.find(x => x.id_micro_regiao === id_micro)
                            return (
                              <div className='col-sm-6 col-md-6' key={`ordem.${categoria.id_categoria}.microregiao.${id_micro}`}>
                                <SmartConditionalInput
                                  id={`ordem.${categoria.id_categoria}.microregiao.${id_micro}`}
                                  label={micro.nome}
                                  placeholder='ordenação'
                                  value={ordemMicroRegiao.find(x => x.id_micro_regiao === id_micro && x.id_categoria === id_categoria)?.ordem}
                                  onChange={(e) => changeMicroOrdem(micro.id_micro_regiao, e.target.value, categoria.id_categoria)}
                                />
                              </div>
                            )
                          })}
                      </div>

                    </Fragment>
                  })}

              </div>
            </div>
          </SmartForm>
        </div>
      </div >
    </div >
  )
}





export default Novo
