import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'

import '../../styles/login/main.css'

import api from '../../services/api'
import { Link } from 'react-router-dom'

function Index(props) {
  const { t } = useTranslation()

  const [error, setError] = useState('')

  const handleSubmit = async event => {
    event.preventDefault()
    const data = new FormData(event.target)

    let post_data = {
      email: data.get('email'),
      senha: data.get('senha'),
      perfil: 'ADMIN'
    }

    api
      .post('/auth/login', post_data)
      .then(res => {
        console.log('User logged in')
        props.setToken(res.data.token, res.data.nome, res.data.change_password)
      })
      .catch(err => {
        const { errors } = err.response.data

        // Usuário ou senha incorreta
        if (errors && errors.length > 0) {
          setError(errors[0].message)
        }
        // erro no post
      })
  }

  console.log('Render: Login')
  return (<div className="App-header">
    <div className="container">
      <div className="container-login">
        <div className="wrap-login">
          <Form className='login-form validate-form' onSubmit={handleSubmit}>

            <span className="login-form-title">
              <img src='/assets/Logo.png' alt='Fy2Go'></img>
            </span>
            <span className="login-form-title">{t('txt.acesse_sua_conta')}</span>

            <div className="wrap-input">
              <input name="email" type="email" className="form_input" autoComplete="off" placeholder=" " />
              <label className="form_label">{t('lbl.email')}</label>
            </div>

            <div className="wrap-input">
              <input name="senha" type="password" className="form_input" autoComplete="off" placeholder=" " />
              <label className="form_label" >{t('lbl.senha')}</label>
            </div>

            {error && <label className='txt-danger'>{error}</label>}

            <div className="container-login-form-btn">
              <button className="login-form-btn"> <div className="entrar">{t('lbl.entrar')}</div> </button>
            </div>

            <Link className="container-recupera-senha" to='/forgotPassword'>{t('txt.esqueci_minha_senha')}</Link>

          </Form>

        </div>
      </div>
    </div>
  </div>
  )
}

export default Index
