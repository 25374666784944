const ambient = 'production'
// const ambient = 'development'

const baseUrl = ambient === 'development'
    ? 'http://localhost:3002'
    // ? 'http://191.252.212.23:3002'
    : 'https://awsapi.fy2go.com.br'

const config = {
    BASEURL: baseUrl,
    APIURL: `${baseUrl}/api`,
    VERSAO: '1.0.0'
}

export default config