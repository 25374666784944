import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import config from './config'

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ptBr',
    debug: true,

    interpolation: {
      escapeValue: false
    },
    backend: {
      crossDomain: true,
      loadPath(lng, ns) {
        const path = `${config.BASEURL}/locales/${lng}.json`;
        return path;
      }
    },
    react: {
      useSuspense: false,
    }
  });


export default i18n;