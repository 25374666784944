import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({

  name: 'sidebar',

  initialState: {
    ativo: false,
  },

  reducers: {

    toggle: (state) => {
      state.ativo = !state.ativo
    },

  },
})

export const { toggle } = sidebarSlice.actions

export default sidebarSlice.reducer