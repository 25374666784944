import React from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'
import { useHeader } from '../header'
import { SmartForm, SmartInput, SmartSelect } from '../smartforms/controls'
import api, { useApi } from '../../services/api'
import useSchema from './schema'

function Novo() {
  const { t } = useTranslation()
  const history = useHistory()
  const schema = useSchema()

  const { title } = useHeader(t('lbl.bairro'), t('lbl.novo'), 'fal fa-map') // informa os dados do cabeçalho interno

  const [cidadesloading, cidades] = useApi('/cidade/pesquisa', title)

  console.log('Render: bairro - Novo')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            config={{ resolver: yupResolver(schema) }}
            onSubmit={async data => {
              const header = t('txt.novo_registro', { $1: title })

              await api
                .post(`/bairro/novo`, data)
                .then(response => {
                  NotificationManager.success(t('txt.registro_salvo'), header, 2000) // exibe alerta de sucesso
                  history.push('./pesquisa') // volta pra tela de pesquisa
                })
                .catch(err => {
                  // erro de validação
                  if (err.response.status === 422) {
                    throw err.response.data.errors
                  }

                  // outro tipo de erro
                  else {
                    NotificationManager.error(t('err.salvar_registro'), header) // exibe alerta padrão
                  }
                })
            }}
          >
            <div className='row'>
              <div className='col-sm-12 col-md-6'>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartSelect
                      id='id_cidade'
                      label={t('lbl.cidade')}
                      name='id_cidade'
                      placeholder={t('lbl.cidade')}
                      options={!cidadesloading
                        ? cidades.map(x => {
                          return {
                            label: x.nome,
                            value: x.id_cidade
                          }
                        }).sort((a, b) => { return a.label.localeCompare(b.label) })
                        : []}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='nome'
                      label={t('lbl.nome')}
                      name='nome'
                      placeholder={t('lbl.nome')}
                    />
                  </div>
                </div>
              </div>

            </div>
          </SmartForm>
        </div>
      </div>
    </div>
  )
}

export default Novo
