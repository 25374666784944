import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHeader } from '../header'
import { Pagination, SmartSimpleTable } from '../smartforms/table'
import api, { useApi } from '../../services/api'
import { SmartForm, SmartSelect } from '../smartforms/controls'


const Filters = ({ onFilter }) => {

  const { t } = useTranslation()

  const { title } = useHeader(t('lbl.local'), t('lbl.pesquisa'), 'fal fa-user') // informa os dados do cabeçalho interno

  const [categoriasloading, categorias] = useApi('/categoria/pesquisa', title)
  const [estadosloading, estados] = useApi('/estado/pesquisa', title)
  const [cidadesloading, cidades] = useApi('/cidade/pesquisa', title)

  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <div className='panel-header' style={{ marginBottom: '20px' }}>
            Filtros
          </div>

          <SmartForm
            goBack={false}
            submitButtonText='Filtrar'
            submitButtonIcon='fal fa-filter'
            onSubmit={async data => {
              onFilter(data)
            }}
          >
            <div className='form-group row'>
              <div className='col-sm-6 col-md-6'>
                <SmartSelect
                  id='categorias'
                  label={t('lbl.categorias')}
                  name='categorias'
                  placeholder={t('lbl.selecione')}
                  multiple={true}
                  options={!categoriasloading && categorias
                    ? categorias.map(item => {
                      return {
                        value: item.id_categoria,
                        label: item.nome
                      }
                    }).sort((a, b) => { return a.label.localeCompare(b.label) })
                    : []} />
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-sm-6 col-md-6'>
                <SmartSelect
                  id='estados'
                  label={t('lbl.estado')}
                  name='estados'
                  placeholder={t('lbl.selecione')}
                  multiple={true}
                  options={!estadosloading && estados
                    ? estados.map(item => {
                      return {
                        value: item.id_estado,
                        label: item.nome
                      }
                    }).sort((a, b) => { return a.label.localeCompare(b.label) })
                    : []} />
              </div>
              <div className='col-sm-6 col-md-6'>
                <SmartSelect
                  id='cidades'
                  label={t('lbl.cidade')}
                  name='cidades'
                  placeholder={t('lbl.selecione')}
                  multiple={true}
                  options={!cidadesloading && cidades
                    ? cidades.map(item => {
                      return {
                        value: item.id_cidade,
                        label: item.nome
                      }
                    }).sort((a, b) => { return a.label.localeCompare(b.label) })
                    : []} />
              </div>
            </div>

          </SmartForm>
        </div>
      </div>
    </div>
  )
}

const Index = () => {
  const history = useHistory()
  const { t } = useTranslation()

  // const [loading, data, setData] = useApi(`/local/pesquisa/?page=1&pageCount=10`, title) // busca todos os registros

  const [data, setData] = useState({ rows: [], total: 0 })
  const [currentPage, setCurrentPage] = useState(0)
  const [pageCount, setPageCount] = useState(10)
  const [searchData, setSearchData] = useState()
  const [filterData, setFilterData] = useState()

  const getData = async (page = 0, count = 10, search = null, data = null) => {

    const postData = {
      page,
      count,
      search,
      ...data
    }

    // let query = `?page=${page}&count=${count}`
    // if (search != null) {
    //   query += `&search=${search}`
    // }

    const response = await api.post(`/local/pesquisa/`, postData)
      .then(res => { return res.data })
    return response
  }

  useEffect(() => {
    getData(currentPage, pageCount, searchData, filterData).then(res => {
      setData(res)
    })
  }, [currentPage, pageCount, searchData, filterData])

  // Colunas para exibir na tabela
  const columns = [
    {
      Header: t('lbl.nome'),
      accessor: 'nome',
      style: {
        width: 350,
        minWidth: 350,
      }
    },

    {
      Header: t('lbl.endereco'),
      accessor: 'endereco',
      style: {
        width: 450,
        minWidth: 450,
      }
    },

    // {
    //   Header: t('lbl.ordenacao'),
    //   accessor: d => {
    //     if (d.Categorias && d.Categorias.length > 0)
    //       return d.Categorias.map(item => {
    //         return `${item.nome} ${item.local_categoria.ordem ? `(${item.local_categoria.ordem}) ` : ' '}`
    //       })
    //   },
    //   style: {
    //     width: 450,
    //     minWidth: 50,
    //   }
    // },
  ]

  console.log('Render: local - Pesquisa')
  return (
    <Fragment>
      <button
        className='btn btn-primary btn-header-new'
        onClick={() => history.push('./novo')}
      >
        {t('lbl.novo')}
      </button>

      <Filters onFilter={(data) => {
        setCurrentPage(0)
        setFilterData(data)
      }} />

      <div className='panel'>
        <div className='panel-container'>
          <div className='panel-content'>
            <SmartSimpleTable
              columns={columns}
              data={data.rows}
              route='/local'
              propID='id_local'
              onFilter={(value) => {
                let search = value?.trim()
                if (search === '') {
                  search = null
                }
                setCurrentPage(0)
                setSearchData(search)
              }}
              setPageCount={(value) => {
                setCurrentPage(0)
                setPageCount(value)
              }}
            />
            <Pagination
              currentPage={currentPage}
              totalRows={data.total}
              pageChangeHandler={setCurrentPage}
              rowsPerPage={pageCount}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Index
