import { configureStore } from '@reduxjs/toolkit'

import sidebarReducer from './sidebarSlice'
import pageHeaderReducer from './pageHeaderSlice'
import userReducer from './userSlice'

export default configureStore({

  reducer: {

    sidebar: sidebarReducer,
    pageHeader: pageHeaderReducer,
    user: userReducer,

  },

})