import React from 'react'
import { useTranslation } from 'react-i18next'

function Item(props) {
  const { t } = useTranslation()

  console.log('Render: usuario - Item')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <dl className='dl-horizontal'>
            <dt>{t('lbl.nome')}</dt>
            <dd>{props.data.nome}</dd>
            <dt>{t('lbl.sobrenome')}</dt>
            <dd>{props.data.sobrenome}</dd>
            <dt>{t('lbl.email')}</dt>
            <dd>{props.data.email}</dd>
            <dt>{t('lbl.telefone')}</dt>
            <dd>{props.data.telefone}</dd>
            <dt>{t('lbl.data_nascimento')}</dt>
            <dd>{props.data.data_nascimento}</dd>
            <dt>{t('lbl.genero')}</dt>
            <dd>{props.data.genero}</dd>
            <dt>{t('lbl.ativo')}</dt>
            <dd>{props.data.ativo}</dd>
            <dt>{t('lbl.estado_civil')}</dt>
            <dd>{props.data.estado_civil}</dd>
            <dt>{t('lbl.tem_filhos')}</dt>
            <dd>{props.data.tem_filhos}</dd>
            <dt>{t('lbl.tem_pets')}</dt>
            <dd>{props.data.tem_pets}</dd>
            <dt>{t('lbl.localizacao')}</dt>
            <dd>{props.data.localizacao}</dd>
            <dt>{t('lbl.privado')}</dt>
            <dd>{props.data.privado}</dd>
            <dt>{t('lbl.foto_url')}</dt>
            <dd>{props.data.foto_url}</dd>
            <dt>{t('lbl.permite_evento')}</dt>
            <dd>{props.data.permite_evento}</dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Item
