import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'
import { useHeader } from '../header'
import { SmartForm, SmartInput, SmartSelect, SmartTextarea } from '../smartforms/controls'
import api, { useApi } from '../../services/api'
import useSchema from './schema'

function Novo() {
  const { t } = useTranslation()
  const history = useHistory()
  const schema = useSchema()

  const tipoLista = [{
    label: 'Para todos',
    value: 1
  }, {
    label: 'Local',
    value: 2
  }, {
    label: 'Perfil',
    value: 3
  }, {
    label: 'Feed',
    value: 4
  }]
  const [tipo, setTipo] = useState(tipoLista[0].value)
  const [feedLista, setFeedLista] = useState([])

  const { title } = useHeader(t('lbl.notificacao'), t('lbl.novo'), 'fal fa-bell') // informa os dados do cabeçalho interno

  const [perfilListaLoading, perfilLista] = useApi('/usuario/lista', title)
  const [localListaLoading, localLista] = useApi('/local/lista', title)

  console.log('Render: Notificação - Novo')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            config={{ resolver: yupResolver(schema) }}
            onSubmit={async data => {
              const header = t('txt.novo_registro', { $1: title })

              const postData = {
                titulo: data.titulo,
                mensagem: data.mensagem,
              }

              if (tipo === 2) {
                postData.id_local = data.id_local
              }
              else if (tipo === 3) {
                postData.id_perfil = data.id_perfil
              }
              else if (tipo === 4) {
                postData.id_perfil = data.id_perfil
                postData.id_feed = data.id_feed
              }

              await api
                .post(`/notificacao/novo`, postData)
                .then(response => {
                  NotificationManager.success(t('txt.registro_salvo'), header, 2000) // exibe alerta de sucesso
                  history.push('./pesquisa') // volta pra tela de pesquisa
                })
                .catch(err => {
                  // erro de validação
                  if (err.response.status === 422) {
                    throw err.response.data.errors
                  }

                  // outro tipo de erro
                  else {
                    NotificationManager.error(t('err.salvar_registro'), header) // exibe alerta padrão
                  }
                })
            }}
          >
            <div className='form-group row'>
              <div className='col-sm-6 col-md-6'>
                <SmartSelect
                  id='tipo'
                  label='Tipo de notificação'
                  name='tipo'
                  placeholder='Tipo de notificação'
                  options={tipoLista}
                  selectedValue={tipo}
                  onSelected={(e) => {
                    setTipo(e)
                  }}
                />
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-sm-6 col-md-6'>
                {tipo === 2 && <SmartSelect
                  id='id_local'
                  label={t('lbl.local')}
                  name='id_local'
                  placeholder={t('lbl.selecione')}
                  options={!localListaLoading && localLista
                    ? localLista.map(item => {
                      return {
                        value: item.id,
                        label: item.nome
                      }
                    }).sort((a, b) => { return a.label.localeCompare(b.label) })
                    : []}
                />}
                {(tipo === 3 || tipo === 4) && <>
                  <div className='form-group row'>
                    <div className='col-sm-12 col-md-12'>
                      <SmartSelect
                        id='id_perfil'
                        label={t('lbl.perfil')}
                        name='id_perfil'
                        placeholder={t('lbl.selecione')}
                        options={!perfilListaLoading && perfilLista
                          ? perfilLista.map(item => {
                            return {
                              value: item.id,
                              label: item.nome
                            }
                          }).sort((a, b) => { return a.label.localeCompare(b.label) })
                          : []}
                        onSelected={(e) => {
                          api.get(`/usuario/feed/${e}`)
                            .then(res => {
                              const { data } = res
                              setFeedLista(data.map(r => {
                                return {
                                  label: r.descricao,
                                  value: r.id
                                }
                              }))
                            })
                        }}
                      />
                    </div>
                  </div>
                  {tipo === 4 && <div className='form-group row'>
                    <div className='col-sm-12 col-md-12'>
                      <SmartSelect
                        id='id_feed'
                        label='Feed'
                        name='id_feed'
                        placeholder={t('lbl.selecione')}
                        options={feedLista.sort((a, b) => { return a.label.localeCompare(b.label) })}
                      />
                    </div>
                  </div>}
                </>
                }
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-12 col-md-6'>
                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='titulo'
                      label={t('lbl.titulo')}
                      name='titulo'
                      placeholder={t('lbl.titulo')}
                      autoFocus
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartTextarea
                      rows={5}
                      id='mensagem'
                      label={t('lbl.mensagem')}
                      name='mensagem'
                      placeholder={t('lbl.mensagem')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </SmartForm>
        </div>
      </div>
    </div>
  )
}

export default Novo
