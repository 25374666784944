import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { useHistory, useParams } from 'react-router-dom'
import { useHeader } from '../header'
import { SmartForm, SmartInput } from '../smartforms/controls'
import api, { useApi } from '../../services/api'

function Editar() {
  const { t } = useTranslation()
  const history = useHistory()
  let { id } = useParams()

  const { title } = useHeader(t('lbl.denuncia'), t('lbl.editar'), 'fal fa-map') // informa os dados do cabeçalho interno
  const [loading, data] = useApi(`/denuncia/detalhes/${id}`, title) // busca dados do registro

  const [situacao, setSituacao] = useState()
  const [isDirty, setIsDirty] = useState(false)

  useEffect(
    () => {

      console.log(data)
      if (data)
        setSituacao(data.situacao)

    }, [data]
  )

  const situacaoChanged = (event) => {
    setSituacao(event.target.value)
    setIsDirty(true)
  }

  if (loading) return <div>{t('txt.busca_registro')}</div>

  console.log('Render: denuncia - Editar')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            isDirty={isDirty}
            config={{ defaultValues: data }}
            onSubmit={async data => {
              const header = t('txt.editar_registro', { $1: title })

              await api
                .put(`/denuncia/${id}`, {
                  situacao
                })
                .then(response => {
                  NotificationManager.success(t('txt.registro_atualizado'), header, 2000)
                  history.push('../pesquisa')
                })
                .catch(err => {
                  if (err.response.status === 422) {
                    throw err.response.data.errors
                  } else {
                    NotificationManager.error(t('err.atualizar_registro'), header)
                  }
                })
            }}
          >
            <div className='row'>
              <div className='col-sm-12 col-md-6'>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <div style={{ paddingLeft: '10px', paddingTop: '20px' }}>
                      <input type="radio" value="P" name="situacao"
                        checked={situacao === 'P'}
                        onChange={situacaoChanged}
                      /> <label style={{ fontSize: '18px' }}>Publicado</label>
                      <input type="radio" value="O" name="situacao"
                        checked={situacao === 'O'}
                        onChange={situacaoChanged}
                        style={{
                          marginLeft: '20px',
                        }}
                      /> <label style={{ fontSize: '18px' }}>Ocultar</label>
                    </div>
                  </div>
                </div>

                <div className='form-group row' style={{ marginTop: '20px' }}>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='nome'
                      label={t('lbl.nome')}
                      name='nome'
                      placeholder={t('lbl.nome')}
                      disabled={true}
                    />
                  </div>
                </div>
                <a target="_blank" href={`/usuario/editar/${data?.id_usuario}`} className='btn btn-success' style={{
                  marginBottom: '20px'
                }}>Ir para o perfil</a>
                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='email'
                      label={t('lbl.email')}
                      name='email'
                      placeholder={t('lbl.email')}
                      disabled={true}
                    />
                  </div>
                </div>

                <h1>Postagem</h1>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    {data.descricao}
                    {data.localizacao && <Fragment>
                      <br />
                      {data.localizacao}
                    </Fragment>}
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    {data.foto_url &&
                      <img
                        alt='foto postagem'
                        src={data.foto_url?.includes('http') ? data.foto_url : `https://firebasestorage.googleapis.com/v0/b/fy2goapp.appspot.com/o/${data.foto_url}`}
                        style={{
                          width: '50%',
                          height: 'auto'
                        }} />
                    }
                  </div>
                </div>

              </div>
            </div>
          </SmartForm>
        </div>
      </div >
    </div >
  )
}

export default Editar
