import React, { Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHeader } from '../header'
import Item from './_detalhes'
import api, { useApi } from '../../services/api'
import { SmartForm, SmartTextarea } from '../smartforms/controls'
import { NotificationManager } from 'react-notifications'

function Detalhes(props) {
  const history = useHistory()
  const { t } = useTranslation()
  let { id } = useParams()

  const { title } = useHeader(t('lbl.suporte'), t('lbl.detalhes'), 'fal fa-user') // informa os dados do cabeçalho interno
  const [loading, data] = useApi(`/suporte/detalhes/${id}`, title) // faz uma requisição get na rota informada

  if (loading) return <div>{t('txt.busca_registro')}</div>

  console.log('Render: suporte - Detalhes')
  return (
    <Fragment>
      <Item data={data} />
      {data.situacao === 'A' && <SmartForm
        submitButtonText='Enviar'
        submitButtonIcon='fal fa-paper-plane'
        onSubmit={async data => {
          const header = t('txt.novo_registro', { $1: title })

          await api
            .post(`/suporte/responder/${id}`, { mensagem: data.mensagem })
            .then(response => {
              NotificationManager.success(t('txt.registro_salvo'), header, 2000) // exibe alerta de sucesso
              history.push('../pesquisa') // volta pra tela de pesquisa
            })
            .catch(err => {
              // erro de validação
              if (err.response.status === 422) {
                throw err.response.data.errors
              }

              // outro tipo de erro
              else {
                NotificationManager.error(t('err.salvar_registro'), header) // exibe alerta padrão
              }
            })
        }}
      >
        <div className='form-group row'>
          <div className='col-sm-12 col-md-12'>
            <SmartTextarea
              rows={5}
              id='mensagem'
              label={t('lbl.mensagem')}
              name='mensagem'
              placeholder={t('lbl.mensagem')}
            />
          </div>
        </div>
      </SmartForm>}
      {data.situacao === 'R' && <div className='page-footer'>
        <div className='row no-gutters'>
          <div className='col-sm-12 col-md-6'></div>
          <div className='col-sm-12 col-md-6 text-right'>
            <button className='btn btn-info' onClick={history.goBack}>
              {t('lbl.voltar')}
            </button>
            {/* <Link
              to={`../editar/${data.id_suporte}`}
              params={data.id_suporte}
              className='btn btn-primary'
            >
              <i className='fal fa-pencil-alt'></i>
              {t('lbl.editar')}
            </Link> */}
          </div>
        </div>
      </div>}
    </Fragment>
  )
}


export default Detalhes;