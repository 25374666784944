import { useState, useEffect } from 'react'
import axios from 'axios'
import TokenService from './token'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'
import config from '../config'
import { useTranslation } from 'react-i18next'
import { logout } from '../stores/userSlice'

const instance = axios.create({
  baseURL: `${config.APIURL}`,
  timeout: 32000,
  headers: {
    'Content-Type': 'application/json'
  }
})

instance.interceptors.request.use(
  async config => {
    const data = await TokenService.getLocalAccessToken()
    if (data) {
      config.headers['x-access-token'] = data.token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  res => {
    return res
  },
  async err => {
    const originalConfig = err.config

    console.log(err)
    if ((err.response?.status === 401 ||
      err.response?.status === 422) &&
      originalConfig.url !== '/auth/signin' &&
      err.response) {
      logout()
      return
    }

    return Promise.reject(err)
  }
)

export const useApi = (url, title) => {
  const history = useHistory()
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {

    // Fetch url
    const fecthData = async function () {
      console.log(`Fetching url ${url} ...`)
      await instance
        .get(url)
        .then(res => {
          setData(res.data)
        })
        .catch(error => {
          console.log(error, error.response)
          NotificationManager.error(t('err.pesquisa_registros'), title)
          history.push('../pesquisa')
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (fetch) fecthData()

  }, [url, title, history, t])

  // Return data
  return [loading, data, setData]
}

export default instance