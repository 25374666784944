import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { t } from 'i18next'

function SidebarMenu() {

  console.log('Sidebar')
  return (
    <ul className='nav-menu list-unstyled components'>

      {/* Menu Administrador */}
      <li>
        <a href={'#menu_administrador'} aria-expanded='false' data-toggle='collapse' className='dropdown-toggle'>
          {t('lbl.operador')}
        </a>
        <ul id='menu_administrador'
          className='nav-menu collapse list-unstyled'>
          <li>
            <Link to='/administrador/pesquisa'>{t('lbl.pesquisa')}</Link>
            <Link to='/administrador/novo'>{t('lbl.novo')}</Link>  </li>
        </ul>
      </li>

      {/* Menu Categoria */}
      <li>
        <a href={'#menu_categoria'} aria-expanded='false' data-toggle='collapse' className='dropdown-toggle'>
          {t('lbl.categoria')}
        </a>
        <ul id='menu_categoria'
          className='nav-menu collapse list-unstyled' >
          <li>
            <Link to='/categoria/pesquisa'>{t('lbl.pesquisa')}</Link>
            <Link to='/categoria/novo'>{t('lbl.novo')}</Link> </li>
        </ul>
      </li>

      {/* Menu Categoria Evento */}
      <li>
        <a href={'#menu_categoria_evento'} aria-expanded='false' data-toggle='collapse' className='dropdown-toggle'>
          {t('lbl.categoria_evento')}
        </a>
        <ul id='menu_categoria_evento'
          className='nav-menu collapse list-unstyled' >
          <li>
            <Link to='/categoria_evento/pesquisa'>{t('lbl.pesquisa')}</Link>
            <Link to='/categoria_evento/novo'>{t('lbl.novo')}</Link> </li>
        </ul>
      </li>

      {/* Menu Bairro */}
      <li>
        <a href={'#menu_bairro'} aria-expanded='false' data-toggle='collapse' className='dropdown-toggle'>
          {t('lbl.bairro')}
        </a>
        <ul id='menu_bairro'
          className='nav-menu collapse list-unstyled'>
          <li>
            <Link to='/bairro/pesquisa'>{t('lbl.pesquisa')}</Link>
            <Link to='/bairro/novo'>{t('lbl.novo')}</Link>  </li>
        </ul>
      </li>

      {/* Menu Micro Região */}
      <li>
        <a href={'#menu_micro_regiao'} aria-expanded='false' data-toggle='collapse' className='dropdown-toggle'>
          {t('lbl.micro_regiao')}
        </a>
        <ul id='menu_micro_regiao'
          className='nav-menu collapse list-unstyled'>
          <li>
            <Link to='/microregiao/pesquisa'>{t('lbl.pesquisa')}</Link>
            <Link to='/microregiao/novo'>{t('lbl.novo')}</Link>  </li>
        </ul>
      </li>

      {/* Menu Local */}
      <li>
        <a href={'#menu_local'} aria-expanded='false' data-toggle='collapse' className='dropdown-toggle'>
          {t('lbl.local')}
        </a>
        <ul id='menu_local'
          className='nav-menu collapse list-unstyled'>
          <li>
            <Link to='/local/pesquisa'>{t('lbl.pesquisa')}</Link>
            <Link to='/local/novo'>{t('lbl.novo')}</Link>  </li>
        </ul>
      </li>

      {/* Menu usuario */}
      <li>
        <a href={'#menu_usuario'} aria-expanded='false' data-toggle='collapse' className='dropdown-toggle'>
          {t('lbl.usuario')}
        </a>
        <ul id='menu_usuario'
          className='nav-menu collapse list-unstyled'>
          <li>
            <Link to='/usuario/pesquisa'>{t('lbl.pesquisa')}</Link>
          </li>
        </ul>
      </li>

      {/* Menu Denuncia */}
      <li>
        <Link to='/denuncia/pesquisa'>{t('txt.ocultar_publicacoes')}</Link>
      </li>

      {/* Menu Suporte */}
      <li>
        <a href={'#menu_suporte'} aria-expanded='false' data-toggle='collapse' className='dropdown-toggle'>
          {t('lbl.suporte')}
        </a>
        <ul id='menu_suporte'
          className='nav-menu collapse list-unstyled' >
          <li>
            <Link to='/suporte/pesquisa'>{t('lbl.pesquisa')}</Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to='/notificacoes/pesquisa'>{t('lbl.notificacoes')}</Link>
      </li>

      <li>
        <Link to='/minhaconta'>{t('lbl.minha_conta')}</Link>
      </li>
    </ul>
  )
}




function Sidebar() {
  const ativo = useSelector(state => state.sidebar.ativo)

  console.log('Render: Sidebar')
  return (
    <nav id='sidebar' className={ativo ? 'active' : null}>
      <SidebarMenu />
    </nav>
  )
}

export default Sidebar
