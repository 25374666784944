import { createSlice } from '@reduxjs/toolkit'
import TokenService from '../services/token'

export const userSlice = createSlice({

  name: 'user',

  initialState: {
    authenticated: false,
    name: '',
    change_password: false,
  },

  reducers: {

    login: (state, action) => {
      state.authenticated = true
      state.name = action.payload.name
      state.change_password = action.payload.change_password
    },

    logout: (state) => {
      state.authenticated = false
      state.name = ''
      state.change_password = false
      TokenService.removeLocalAccessToken(null, null)
    },

    changePassword: (state, action) => {
      state.change_password = action.payload
    }

  },
})

export const { login, logout, changePassword } = userSlice.actions

export default userSlice.reducer