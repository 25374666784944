import React from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'
import { useHeader } from '../header'
import { SmartCheckBox, SmartForm, SmartInput } from '../smartforms/controls'
import api from '../../services/api'
import useSchema from './schema'

function Novo() {
  const { t } = useTranslation()
  const history = useHistory()
  const schema = useSchema()

  const { title } = useHeader(t('lbl.usuario'), t('lbl.novo'), 'fal fa-user') // informa os dados do cabeçalho interno

  console.log('Render: usuario - Novo')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            config={{ resolver: yupResolver(schema) }}
            onSubmit={async data => {
              const header = t('txt.novo_registro', { $1: title })

              await api
                .post(`/usuario/novo`, data)
                .then(response => {
                  NotificationManager.success(t('txt.registro_salvo'), header, 2000) // exibe alerta de sucesso
                  history.push('./pesquisa') // volta pra tela de pesquisa
                })
                .catch(err => {
                  // erro de validação
                  if (err.response.status === 422) {
                    throw err.response.data.errors
                  }

                  // outro tipo de erro
                  else {
                    NotificationManager.error(t('err.salvar_registro'), header) // exibe alerta padrão
                  }
                })
            }}
          >
            <div className='row'>
              <div className='col-sm-12 col-md-6'>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='nome'
                      label={t('lbl.nome')}
                      name='nome'
                      placeholder={t('lbl.nome')}
                    />
                  </div>
                </div>


                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='sobrenome'
                      label={t('lbl.sobrenome')}
                      name='sobrenome'
                      placeholder={t('lbl.sobrenome')}

                    />
                  </div>
                </div>


                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='email'
                      label={t('lbl.email')}
                      name='email'
                      placeholder={t('lbl.email')}

                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='telefone'
                      label={t('lbl.telefone')}
                      name='telefone'
                      placeholder={t('lbl.telefone')}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='data_nascimento'
                      label={t('lbl.data_nascimento')}
                      name='data_nascimento'
                      placeholder={t('lbl.data_nascimento')}
                      format='DD/MM/YYYY'
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartCheckBox
                      id='ativo'
                      label={t('lbl.ativo')}
                      name='ativo'
                    />
                    <SmartCheckBox
                      id='bloqueado'
                      label={t('lbl.bloqueado')}
                      name='bloqueado'
                    />
                    <SmartCheckBox
                      id='permite_evento'
                      label={t('lbl.permite_evento')}
                      name='permite_evento'
                    />
                  </div>
                </div>

              </div>
            </div>
          </SmartForm>
        </div>
      </div>
    </div>
  )
}

export default Novo
