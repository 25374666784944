import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

function Item(props) {
  const { t } = useTranslation()

  console.log('Render: categoria - Item')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <dl className='dl-horizontal'>
            <dt>{t('lbl.nome')}</dt>
            <dd>{props.data.nome}</dd>
            {!props.data.CategoriasPai || props.data.CategoriasPai.length == 0 &&
              <Fragment><dt>{t('lbl.ordenacao')}</dt>
                <dd>{props.data.ordenacao}</dd>
              </Fragment>}
            <dt>{t('lbl.descricao')}</dt>
            <dd>{props.data.descricao}</dd>
          </dl>
          {props.data.CategoriasPai && props.data.CategoriasPai.length > 0 &&
            <Fragment>
              <h1>Categorias Pai</h1>
              <dl className='dl-horizontal'>
                {props.data.CategoriasPai.map(item => {
                  return (
                    <Fragment>
                      <dt>{item.CategoriaPai.descricao}</dt>
                      <dd>{item.ordem ? `ordem - ${item.ordem}` : ''}</dd>
                    </Fragment>
                  )
                })}
              </dl>
            </Fragment>
          }
        </div>
      </div>
    </div>
  )
}

export default Item
