import React from 'react'
import { useTranslation } from 'react-i18next'

function Item(props) {
  const { t } = useTranslation()

  console.log('Render: Notificação - Item')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <dl className='dl-horizontal'>
            <dt>{t('lbl.titulo')}</dt>
            <dd>{props.data.titulo}</dd>

            <dt>{t('lbl.mensagem')}</dt>
            <dd>{props.data.mensagem}</dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Item
