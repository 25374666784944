import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggle } from '../stores/sidebarSlice'
import { logout } from '../stores/userSlice'
import { setTitle } from '../stores/pageHeaderSlice'
import api from '../services/api'
import { t } from 'i18next'

function SidebarCollapseButton() {
  const ativo = useSelector(state => state.sidebar.ativo)
  const dispatch = useDispatch()

  return (
    <button
      type='button'
      id='sidebarCollapse'
      className={ativo ? 'active' : null}
      onClick={() => dispatch(toggle())}
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}

function ContentHeader() {
  const dispatch = useDispatch()

  const user = useSelector(state => state.user) // usuário redux

  console.log('Render: Header')
  return (
    <div className='d-flex flex-column'>
      <div className='header-app'>
        <nav className='navbar navbar-expand-lg navbar-light bg-light'>
          <div className='container-fluid d-block'>
            <SidebarCollapseButton />
            <div className='float-right'>
              <label style={{ marginRight: '10px' }}>{user.name}</label>
              <button onClick={() => {
                api.post('/auth/logout')
                  .then(() => {
                    dispatch(logout())
                  })
              }}>{t('lbl.sair')}</button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export const useHeader = (title, subTitle, icon) => {
  const dispatch = useDispatch()

  useEffect(() => {
    console.log(`Set title: ${title}`)
    dispatch(setTitle({ title, subTitle, icon }))
  }, [dispatch, title, subTitle, icon])

  return { title }
}

export default ContentHeader
