import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useHeader } from '../header'
import SmartTable from '../smartforms/table'
import { useApi } from '../../services/api'

function Index() {
  const { t } = useTranslation()

  const { title } = useHeader(t('lbl.suporte'), t('lbl.pesquisa'), 'fal fa-user') // informa os dados do cabeçalho interno
  const [loading, data] = useApi(`/suporte/pesquisa`, title) // busca todos os registros

  // Colunas para exibir na tabela
  const columns = [

    {
      Header: t('lbl.ID'),
      accessor: 'id_suporte',
      style: {
        width: 80,
        minWidth: 80,
      }
    },

    {
      Header: t('lbl.situacao'),
      accessor: 'situacao',
      style: {
        width: 200,
        minWidth: 200,
      }
    },

    {
      Header: t('lbl.assunto'),
      accessor: 'assunto',
      style: {
        width: 450,
        minWidth: 200,
      }
    },

    {
      Header: t('lbl.motivo'),
      accessor: 'motivo',
      style: {
        width: 450,
        minWidth: 200,
      }
    },


    {
      Header: t('lbl.descricao'),
      accessor: 'descricao',
      style: {
        width: 450,
        minWidth: 200,
      }
    },

    {
      Header: t('lbl.usuario'),
      accessor: 'nome_usuario',
      style: {
        width: 450,
        minWidth: 200,
      }
    }

  ]

  if (loading) return <div>{t('txt.busca_registros')}</div>

  console.log('Render: suporte - Pesquisa')
  return (
    <Fragment>
      {/* <button
        className='btn btn-primary btn-header-new'
        onClick={() => history.push('./novo')}
      >
        {t('lbl.novo')}
      </button> */}

      <div className='panel'>
        <div className='panel-container'>
          <div className='panel-content'>
            <SmartTable
              columns={columns}
              data={data}
              route='/suporte'
              propID='id_suporte'
              editClass={() => { return 'disabled' }}
            ></SmartTable>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Index
