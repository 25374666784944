import React, { Fragment, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'

import '../../styles/login/main.css'

import api from '../../services/api'
import { Link, useHistory, useLocation } from 'react-router-dom'

function ChangePassword(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const [error, setError] = useState('')

  useEffect(
    () => {

      if (!location.state.email)
        history.push('./login')

    }, [location, history]
  )

  const handleSubmit = async event => {
    event.preventDefault()
    const data = new FormData(event.target)

    const senha = data.get('senha')
    const confirma_senha = data.get('confirmar_senha')

    if (!senha)
      return setError('Senhas diferentes')

    if (!confirma_senha)
      return setError('Senhas diferentes')

    if (senha !== confirma_senha)
      return setError('Senhas diferentes')

    let post_data = {
      email: location.state.email,
      code: location.state.codigo,
      password: senha,
      profile: 'ADMIN'
    }

    api
      .post('/user/resetPassword', post_data)
      .then(res => {
        history.push('./login')
      })
      .catch(err => {
        setError(err.response.data)
      })
  }

  console.log('Render: ChangePassword')
  return (<div className="App-header">
    <div className="container">
      <div className="container-login">
        <div className="wrap-login">
          <Form className='login-form validate-form' onSubmit={handleSubmit}>

            <span className="login-form-title">
              <img src='/assets/Logo.png' alt='Fy2Go'></img>
            </span>
            <span className="login-form-title">{t('txt.alterar_senha')}</span>

            <Fragment>
              <div className="wrap-input">
                <input name="senha" type="senha" className="form_input" autoComplete="off" placeholder=" " />
                <label className="form_label">{t('lbl.senha')}</label>
                {error && <label className='txt-danger' style={{ marginTop: 0 }}>{error}</label>}
              </div>

              <div className="wrap-input">
                <input name="confirmar_senha" type="confirmar_senha" className="form_input" autoComplete="off" placeholder=" " />
                <label className="form_label">{t('lbl.confirmar_senha')}</label>
                {error && <label className='txt-danger' style={{ marginTop: 0 }}>{error}</label>}
              </div>

              <div className="container-login-form-btn">
                <button className="login-form-btn"> <div className="entrar">{t('txt.trocar_senha')}</div> </button>
              </div>

              <Link className="container-recupera-senha" to='/login'>{t('lbl.voltar')}</Link>
            </Fragment>

          </Form>

        </div>
      </div>
    </div>
  </div>
  )
}

export default ChangePassword
