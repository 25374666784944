import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const useSchema = () => {
  const { t } = useTranslation()

  const msg_campoobrigatorio = t('err.campo_obrigatorio')

  return yup
    .object({
      // email: yup
      //   .string()
      //   .required(msg_campoobrigatorio)
      //   .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
      // nome: yup.string().required(msg_campoobrigatorio),
      // senha: yup.string().required(msg_campoobrigatorio),
      // descricao: yup.string().required(msg_campoobrigatorio)
    })
    .required()
}

export const useEditarSchema = () => {
  const { t } = useTranslation()

  const msg_campoobrigatorio = t('err.campo_obrigatorio')

  return yup
    .object({
      // email: yup
      //   .string()
      //   .required(msg_campoobrigatorio)
      //   .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
      // nome: yup.string().required(msg_campoobrigatorio),
    })
    .required()
}

export default useSchema
