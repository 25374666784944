import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const useSchema = () => {
  const { t } = useTranslation()

  const msg_campoobrigatorio = t('err.campo_obrigatorio')

  return yup
    .object({
      nome: yup.string().required(msg_campoobrigatorio),
      id_cidade: yup.number().required(msg_campoobrigatorio),
    })
    .required()
}

export const useEditarSchema = () => {
  const { t } = useTranslation()

  const msg_campoobrigatorio = t('err.campo_obrigatorio')

  return yup
    .object({
      nome: yup.string().required(msg_campoobrigatorio),
      id_cidade: yup.number().required(msg_campoobrigatorio),
    })
    .required()
}

export default useSchema
