import React, { Fragment, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'

import '../../styles/login/main.css'

import api from '../../services/api'
import { Link, useHistory } from 'react-router-dom'

function ForgotPassword(props) {
  const { t } = useTranslation()
  const history = useHistory()

  const [error, setError] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [email, setEmail] = useState()

  const handleSubmit = async event => {
    event.preventDefault()
    const data = new FormData(event.target)

    if (!emailSent) {

      if (!data.get('email'))
        return setError('Campo obrigatorio.')

      let post_data = {
        email: data.get('email'),
        profile: 'ADMIN'
      }

      api
        .post('/user/forgotPassword', post_data)
        .then(res => {
          setEmailSent(true)
        })
        .catch(err => {
          const { errors } = err.response.data

          // Usuário ou senha incorreta
          if (errors && errors.length > 0) {
            setError(errors[0].message)
          }
          // erro no post
        })

    }
    else {

      const codigo = data.get('codigo')
      if (!codigo)
        return setError('Campo obrigatorio.')

      let post_data = {
        email: email,
        code: codigo,
        profile: 'ADMIN'
      }

      api
        .post('/user/checkCode', post_data)
        .then(res => {

          history.push({
            state: { email, codigo },
            pathname: '/changePassword'
          })

        })
        .catch(err => {
          setError(err.response.data)
        })

    }
  }

  console.log('Render: ForgotPassword')
  return (<div className="App-header">
    <div className="container">
      <div className="container-login">
        <div className="wrap-login">
          <Form className='login-form validate-form' onSubmit={handleSubmit}>

            <span className="login-form-title">
              <img src='/assets/Logo.png' alt='Fy2Go'></img>
            </span>
            <span className="login-form-title">{t('txt.esqueci_minha_senha')}</span>

            {!emailSent
              ?
              <Fragment>
                <div className="wrap-input">
                  <input onChange={(e) => setEmail(e.target.value)} key='email' id='email' name="email" className="form_input" autoComplete="off" placeholder=" " />
                  <label className="form_label">{t('lbl.email')}</label>
                  {error && <label className='txt-danger' style={{ marginTop: 0 }}>{error}</label>}
                </div>

                <div className="container-login-form-btn">
                  <button className="login-form-btn"> <div className="entrar">{t('lbl.recuperar_senha')}</div> </button>
                </div>

                <Link className="container-recupera-senha" to='/login'>{t('lbl.voltar')}</Link>
              </Fragment>
              :
              <Fragment>
                <h3 style={{ color: '#000' }}>Informe o código enviado para o e-mail.</h3>
                <div className="wrap-input">
                  <input key='codigo' id='codigo' name="codigo" className="form_input" autoComplete="off" placeholder=" " />
                  <label className="form_label">{t('lbl.codigo')}</label>
                  {error && <label className='txt-danger' style={{ marginTop: 0 }}>{error}</label>}
                </div>

                <div className="container-login-form-btn">
                  <button className="login-form-btn"> <div className="entrar">Confirmar código</div> </button>
                </div>

                <Link className="container-recupera-senha" to='/forgotPassword' onClick={() => {
                  setEmailSent(false)
                  setError('')
                }}>
                  {t('lbl.voltar')}
                </Link>
              </Fragment>
            }

          </Form>

        </div>
      </div>
    </div>
  </div>
  )
}

export default ForgotPassword
