import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'
import { useHeader } from '../header'
import { SmartForm, SmartInput, SmartTextarea } from '../smartforms/controls'
import api from '../../services/api'
import useSchema from './schema'

function Novo() {
  const { t } = useTranslation()
  const history = useHistory()
  const schema = useSchema()

  const { title } = useHeader(t('lbl.categoria_evento'), t('lbl.novo'), 'fal fa-user') // informa os dados do cabeçalho interno

  const [selectedImage, setSelectedImage] = useState()

  const [isDirty, setIsDirty] = useState(false)

  console.log('Render: categoria evento - Novo')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            isDirty={isDirty}
            config={{ resolver: yupResolver(schema) }}
            onSubmit={async data => {
              const header = t('txt.novo_registro', { $1: title })

              var formData = new FormData()
              formData.append('nome', data.nome)
              formData.append('ordenacao', data.ordenacao)
              formData.append('descricao', data.descricao)

              if (selectedImage)
                formData.append('media', selectedImage.file)

              await api
                .post(`/categoria_evento/novo`, formData, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(response => {
                  NotificationManager.success(t('txt.registro_salvo'), header, 2000) // exibe alerta de sucesso
                  history.push('./pesquisa') // volta pra tela de pesquisa
                })
                .catch(err => {
                  // erro de validação
                  if (err.response.status === 422) {
                    throw err.response.data.errors
                  }

                  // outro tipo de erro
                  else {
                    NotificationManager.error(t('err.salvar_registro'), header) // exibe alerta padrão
                  }
                })
            }}
          >
            <div className='row'>
              <div className='col-sm-12 col-md-6'>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='nome'
                      label={t('lbl.nome')}
                      name='nome'
                      placeholder={t('lbl.nome')}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='ordenacao'
                      label={t('lbl.ordenacao')}
                      name='ordenacao'
                      placeholder={t('lbl.ordenacao')}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartTextarea
                      rows={5}
                      id='descricao'
                      label={t('lbl.descricao')}
                      name='descricao'
                      placeholder={t('lbl.descricao')}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-6 col-md-6'>
                    <label className='field-label'>Foto</label>
                    <br />
                    <input
                      type="file"
                      id="media"
                      style={{ marginTop: '10px' }}
                      onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                          setIsDirty(true)
                          let reader = new FileReader()
                          reader.onload = (e) => {
                            setSelectedImage({
                              image: e.target.result,
                              file: event.target.files[0]
                            })
                          }
                          reader.readAsDataURL(event.target.files[0]);
                        }
                      }}
                    />

                    {selectedImage && <img alt='background' src={selectedImage.image}
                      style={{
                        width: '150px',
                        height: '150px',
                        borderRadius: '25px',
                        objectFit: 'cover',
                        marginTop: '20px'
                      }}
                    />}
                  </div>
                </div>
              </div>

            </div>
          </SmartForm>

        </div>
      </div>
    </div>
  )
}

export default Novo
