import React from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory, useParams } from 'react-router-dom'
import { useHeader } from '../header'
import { SmartForm, SmartInput, SmartSelect } from '../smartforms/controls'
import api, { useApi } from '../../services/api'
import { useEditarSchema } from './schema'

function Editar() {
  const { t } = useTranslation()
  const history = useHistory()
  const schema = useEditarSchema()
  let { id } = useParams()

  const { title } = useHeader(t('lbl.bairro'), t('lbl.editar'), 'fal fa-map') // informa os dados do cabeçalho interno
  const [loading, data] = useApi(`/bairro/detalhes/${id}`, title) // busca dados do registro

  const [cidadesloading, cidades] = useApi('/cidade/pesquisa', title)

  if (loading) return <div>{t('txt.busca_registro')}</div>

  console.log('Render: bairro - Editar')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            config={{ defaultValues: data, resolver: yupResolver(schema) }}
            onSubmit={async data => {
              const header = t('txt.editar_registro', { $1: title })

              await api
                .put(`/bairro/${id}`, data)
                .then(response => {
                  NotificationManager.success(t('txt.registro_atualizado'), header, 2000)
                  history.push('../pesquisa')
                })
                .catch(err => {
                  if (err.response.status === 422) {
                    throw err.response.data.errors
                  } else {
                    NotificationManager.error(t('err.atualizar_registro'), header)
                  }
                })
            }}
          >


            <div className='row'>
              <div className='col-sm-12 col-md-6'>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartSelect
                      id='id_cidade'
                      label={t('lbl.cidade')}
                      name='id_cidade'
                      placeholder={t('lbl.cidade')}
                      options={!cidadesloading
                        ? cidades.map(x => {
                          return {
                            label: x.nome,
                            value: x.id_cidade
                          }
                        }).sort((a, b) => { return a.label.localeCompare(b.label) })
                        : []}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='nome'
                      label={t('lbl.nome')}
                      name='nome'
                      placeholder={t('lbl.nome')}
                    />
                  </div>
                </div>

              </div>
            </div>
          </SmartForm>
        </div>
      </div>
    </div>
  )
}

export default Editar
