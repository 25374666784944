import React from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory, useParams } from 'react-router-dom'
import { useHeader } from '../header'
import { SmartCheckBox, SmartDatetime, SmartForm, SmartInput } from '../smartforms/controls'
import api, { useApi } from '../../services/api'
import { useEditarSchema } from './schema'

function Editar() {
  const { t } = useTranslation()
  const history = useHistory()
  const schema = useEditarSchema()
  let { id } = useParams()

  const { title } = useHeader(t('lbl.usuario'), t('lbl.editar'), 'fal fa-user') // informa os dados do cabeçalho interno
  const [loading, data] = useApi(`/usuario/detalhes/${id}`, title) // busca dados do registro

  if (loading) return <div>{t('txt.busca_registro')}</div>

  console.log('Render: usuario - Editar')
  return (
    <div className='panel'>
      <div className='panel-container'>
        <div className='panel-content'>
          <SmartForm
            config={{ defaultValues: data, resolver: yupResolver(schema) }}
            onSubmit={async data => {
              const header = t('txt.editar_registro', { $1: title })

              await api
                .put(`/usuario/${id}`, data)
                .then(response => {
                  NotificationManager.success(t('txt.registro_atualizado'), header, 2000)
                  history.push('../pesquisa')
                })
                .catch(err => {
                  if (err.response.status === 422) {
                    throw err.response.data.errors
                  } else {
                    NotificationManager.error(t('err.atualizar_registro'), header)
                  }
                })
            }}
          >
            <div className='row'>
              <div className='col-sm-12 col-md-6'>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='nome'
                      label={t('lbl.nome')}
                      name='nome'
                      placeholder={t('lbl.nome')}
                    />
                  </div>
                </div>


                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='sobrenome'
                      label={t('lbl.sobrenome')}
                      name='sobrenome'
                      placeholder={t('lbl.sobrenome')}

                    />
                  </div>
                </div>


                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='email'
                      label={t('lbl.email')}
                      name='email'
                      placeholder={t('lbl.email')}

                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartInput
                      id='telefone'
                      label={t('lbl.telefone')}
                      name='telefone'
                      placeholder={t('lbl.telefone')}
                    />
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-sm-12 col-md-12'>
                    <SmartDatetime
                      id='data_nascimento'
                      label={t('lbl.data_nascimento')}
                      name='data_nascimento'
                      format='DD/MM/YYYY'
                    />
                  </div>
                </div>

                <div className='form-group row'
                  style={{
                    marginTop: '20px'
                  }}>
                  <div className='col-sm-12 col-md-12'>

                    <SmartCheckBox
                      id='ativo'
                      label={t('lbl.ativo')}
                      name='ativo'
                    />
                    <SmartCheckBox
                      id='bloqueado'
                      label={t('lbl.bloqueado')}
                      name='bloqueado'
                    />
                    <SmartCheckBox
                      id='permite_evento'
                      label={t('lbl.permite_evento')}
                      name='permite_evento'
                    />
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6'>
                <div className='row'>
                  <img alt='background' src={'https://firebasestorage.googleapis.com/v0/b/fy2goapp.appspot.com/o/' + data.foto_url}
                    style={{
                      width: '150px',
                      height: '150px',
                      borderRadius: '25px',
                      objectFit: 'cover',
                      marginTop: '20px'
                    }}
                  />
                </div>
              </div>
            </div>
          </SmartForm>
        </div>
      </div>
    </div>
  )
}

export default Editar
