import React, { useState, Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination
} from 'react-table'
import { t } from 'i18next'

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  onChangeHandler,
}) {

  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = value => {
    setGlobalFilter(value || undefined)
  }

  return (
    <div className='dataTable-filter'>
      <div className='row'>
        <div className='col-sm-12 col-md-6'>
          <div className='field-group'>
            <input
              id="txt-pesquisa"
              autoComplete='off'
              className='field-input'
              name='pesquisa-dataTable'
              value={value || ''}
              onChange={e => {
                setValue(e.target.value)
                onChange(e.target.value)
                onChangeHandler(e.target.value)
              }}
              placeholder={`${count} registros...`}
              required
            />
            <label htmlFor="txt-pesquisa" className='field-label'>{t('lbl.pesquisa')}</label>
          </div>
        </div>
      </div>
    </div>
  )
}

const range = (from, to, max, step = 1) => {
  let i = from >= 0 ? from : 0
  to = to > max ? max : to

  const range = []

  while (i < to) {
    range.push(i)
    i += step
  }

  return range
}

function Exibindo(props) {
  var inicio = props.index === 0 ? 1 : props.index * props.size + 1

  var total = (props.index + 1) * props.size
  if (total > props.rows) total = props.rows

  return (
    <Fragment>
      {t('lbl.exibindo')} {inicio} {t('lbl.ate')} {total}{' '}
      {t('lbl.de')} {props.rows} {t('lbl.registros')}
    </Fragment>
  )
}

export function Pagination({
  currentPage,
  pageChangeHandler,
  totalRows,
  rowsPerPage
}) {

  const pageCount = Math.ceil(totalRows / rowsPerPage)
  const [pageIndex, setPageIndex] = useState(0)

  const [canGoBack, setCanGoBack] = useState(false)
  const [canGoNext, setCanGoNext] = useState(true)

  const onNextPage = () => setPageIndex(pageIndex + 1)
  const onPrevPage = () => setPageIndex(pageIndex - 1)
  const onPageSelect = (pageNo) => setPageIndex(pageNo)

  useEffect(() => {
    setPageIndex(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (pageIndex === pageCount - 1) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (pageIndex === 0) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [pageCount, pageIndex]);

  useEffect(() => {
    pageChangeHandler(pageIndex);
  }, [pageIndex]);


  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5'>
        <div className='dataTables_info'>
          <Exibindo index={pageIndex} size={rowsPerPage} rows={totalRows} />
        </div>
      </div>
      <div className='col-sm-12 col-md-7'>
        <div className='dataTables_paginate paging_simple_numbers'>
          <ul className='pagination'>
            <li className='paginate_button page-item previous'>
              <button
                className='page-link'
                onClick={() => onPrevPage()}
                disabled={!canGoBack}
              >
                <i className='fal fa-chevron-left'></i>
              </button>
            </li>
            {range(pageIndex - 2, pageIndex + 2, pageCount).map(page => {
              return (
                <li
                  key={page}
                  className={
                    page === pageIndex
                      ? 'paginate_button page-item active'
                      : 'paginate_button page-item'
                  }
                  onClick={() => onPageSelect(page)}
                >
                  <button className='page-link'>{page + 1}</button>
                </li>
              )
            })}
            <li>
              <button
                className='page-link'
                onClick={() => onNextPage()}
                disabled={!canGoNext}
              >
                <i className='fal fa-chevron-right'></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function SimpleFilter({
  onChangeHandler,
}) {

  const [value, setValue] = useState('')

  return (
    <div className='dataTable-filter'>
      <div className='row'>
        <div className='col-sm-12 col-md-6'>
          <div className='field-group'>
            <input
              id="txt-pesquisa"
              autoComplete='off'
              className='field-input'
              name='pesquisa-dataTable'
              value={value || ''}
              onChange={e => {
                setValue(e.target.value)
                onChangeHandler(e.target.value)
              }}
              placeholder={''}
              required
            />
            <label htmlFor="txt-pesquisa" className='field-label'>{t('lbl.pesquisa')}</label>
          </div>
        </div>
      </div>
    </div>
  )
}

export function SmartSimpleTable({
  columns,
  data,
  route,
  propID,
  editClass,
  detailsClass,
  deleteClass,
  onFilter,
  setPageCount
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page,
    setPageSize,
    state: { pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  console.log('Render: Table')
  return (
    <div className='dataTable-wrapper'>
      <div className='dataTable-header'>
        <SimpleFilter
          onChangeHandler={onFilter}
        />

        <select
          className='form-control custom-select'
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
            if (setPageCount) {
              setPageCount(e.target.value)
            }
          }}
        >
          {[10, 50, 100].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {t('lbl.exibir')} {pageSize}
            </option>
          ))}
        </select>
      </div>

      <div className='table-wrapper custom-horizontal-scroll'>
        <table {...getTableProps()} className='table dataTable'>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(
                  column =>
                    !column.hidden && (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({
                            style: { ...column.style }
                          })
                        )}
                        className={
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'sorting_desc'
                              : 'sorting_asc'
                            : 'sorting'
                        }
                      >
                        {column.render('Header')}
                      </th>
                    )
                )}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr key={i}>
                  {row.cells.map((cell, i) => {
                    var editExtraClass = editClass && editClass(cell)
                    var detailsExtraClass = detailsClass && detailsClass(cell)
                    var deleteExtraClass = deleteClass && deleteClass(cell)

                    return i === 0 ? (
                      <td
                        {...cell.getCellProps({
                          style: { ...cell.column.style }
                        })}
                        className='position-relative'
                      >
                        <div className='edit-buttons'>
                          <ul>
                            <li>
                              <Link
                                to={
                                  route + '/editar/' + cell.row.original[propID]
                                }
                                params={cell.row.original[propID]}
                                // data-tip data-for='EditarTip'
                                className={`btn btn-primary ${editExtraClass}`}
                              >
                                <i className='fal fa-pen-alt'></i>
                              </Link>
                              {/* <ReactTooltip id='EditarTip'>Editar</ReactTooltip> */}
                            </li>
                            <li>
                              <Link
                                to={
                                  route +
                                  '/detalhes/' +
                                  cell.row.original[propID]
                                }
                                params={cell.row.original[propID]}
                                // data-tip data-for='VerTip'
                                className={`btn btn-primary ${detailsExtraClass}`}
                              >
                                <i className='fal fa-book-open'></i>
                              </Link>
                              {/* <ReactTooltip id='VerTip'>Ver</ReactTooltip> */}
                            </li>
                            <li>
                              <Link
                                to={
                                  route +
                                  '/excluir/' +
                                  cell.row.original[propID]
                                }
                                params={cell.row.original[propID]}
                                // data-tip data-for='ExcluirTip'
                                className={`btn btn-danger ${deleteExtraClass}`}
                              >
                                <i className='fal fa-trash-alt'></i>
                              </Link>
                              {/* <ReactTooltip id='ExcluirTip'>Excluir</ReactTooltip> */}
                            </li>
                          </ul>
                        </div>
                        <span className='pa index-1'>
                          {cell.render('Cell')}
                        </span>
                      </td>
                    ) : (
                      !cell.column.hidden && (
                        <td
                          {...cell.getCellProps({
                            style: { ...cell.column.style }
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function SmartTable({
  columns,
  data,
  route,
  propID,
  editClass,
  detailsClass,
  deleteClass
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  console.log('Render: Table')
  return (
    <div className='dataTable-wrapper'>
      <div className='dataTable-header'>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />

        <select
          className='form-control custom-select'
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 50, 100].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {t('lbl.exibir')} {pageSize}
            </option>
          ))}
        </select>
      </div>

      <div className='table-wrapper custom-horizontal-scroll'>
        <table {...getTableProps()} className='table dataTable'>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(
                  column =>
                    !column.hidden && (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({
                            style: { ...column.style }
                          })
                        )}
                        className={
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'sorting_desc'
                              : 'sorting_asc'
                            : 'sorting'
                        }
                      >
                        {column.render('Header')}
                      </th>
                    )
                )}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr key={i}>
                  {row.cells.map((cell, i) => {
                    var editExtraClass = editClass && editClass(cell)
                    var detailsExtraClass = detailsClass && detailsClass(cell)
                    var deleteExtraClass = deleteClass && deleteClass(cell)

                    return i === 0 ? (
                      <td
                        {...cell.getCellProps({
                          style: { ...cell.column.style }
                        })}
                        className='position-relative'
                      >
                        <div className='edit-buttons'>
                          <ul>
                            <li>
                              <Link
                                to={
                                  route + '/editar/' + cell.row.original[propID]
                                }
                                params={cell.row.original[propID]}
                                // data-tip data-for='EditarTip'
                                className={`btn btn-primary ${editExtraClass}`}
                              >
                                <i className='fal fa-pen-alt'></i>
                              </Link>
                              {/* <ReactTooltip id='EditarTip'>Editar</ReactTooltip> */}
                            </li>
                            <li>
                              <Link
                                to={
                                  route +
                                  '/detalhes/' +
                                  cell.row.original[propID]
                                }
                                params={cell.row.original[propID]}
                                // data-tip data-for='VerTip'
                                className={`btn btn-primary ${detailsExtraClass}`}
                              >
                                <i className='fal fa-book-open'></i>
                              </Link>
                              {/* <ReactTooltip id='VerTip'>Ver</ReactTooltip> */}
                            </li>
                            <li>
                              <Link
                                to={
                                  route +
                                  '/excluir/' +
                                  cell.row.original[propID]
                                }
                                params={cell.row.original[propID]}
                                // data-tip data-for='ExcluirTip'
                                className={`btn btn-danger ${deleteExtraClass}`}
                              >
                                <i className='fal fa-trash-alt'></i>
                              </Link>
                              {/* <ReactTooltip id='ExcluirTip'>Excluir</ReactTooltip> */}
                            </li>
                          </ul>
                        </div>
                        <span className='pa index-1'>
                          {cell.render('Cell')}
                        </span>
                      </td>
                    ) : (
                      !cell.column.hidden && (
                        <td
                          {...cell.getCellProps({
                            style: { ...cell.column.style }
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className='row'>
        <div className='col-sm-12 col-md-5'>
          <div className='dataTables_info'>
            <Exibindo index={pageIndex} size={pageSize} rows={rows.length} />
          </div>
        </div>
        <div className='col-sm-12 col-md-7'>
          <div className='dataTables_paginate paging_simple_numbers'>
            <ul className='pagination'>
              <li className='paginate_button page-item previous'>
                <button
                  className='page-link'
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <i className='fal fa-chevron-left'></i>
                </button>
              </li>
              {range(pageIndex - 2, pageIndex + 2, pageCount).map(page => {
                return (
                  <li
                    key={page}
                    className={
                      page === pageIndex
                        ? 'paginate_button page-item active'
                        : 'paginate_button page-item'
                    }
                    onClick={() => gotoPage(page)}
                  >
                    <button className='page-link'>{page + 1}</button>
                  </li>
                )
              })}
              <li>
                <button
                  className='page-link'
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <i className='fal fa-chevron-right'></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmartTable
