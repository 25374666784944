import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import MinhaConta from './minha_conta/index'
import AlterarSenha from './minha_conta/alterar_senha'

import Notificacoes from './notificacao/index'
import NotificacaoNovo from './notificacao/novo'
import NotificacaoDetalhes from './notificacao/detalhes'

// CRUD de administrador
import AdminIndex from './administrador/index'
import AdminNovo from './administrador/novo'
import AdminDetalhes from './administrador/detalhes'
import AdminExcluir from './administrador/excluir'
import AdminEditar from './administrador/editar'

// CRUD de Categoria
import CatetoriaIndex from './categoria/index'
import CatetoriaNovo from './categoria/novo'
import CatetoriaDetalhes from './categoria/detalhes'
import CatetoriaExcluir from './categoria/excluir'
import CatetoriaEditar from './categoria/editar'

// CRUD de Categoria Evento
import CatetoriaEventoIndex from './categoria_evento/index'
import CatetoriaEventoNovo from './categoria_evento/novo'
import CatetoriaEventoDetalhes from './categoria_evento/detalhes'
import CatetoriaEventoExcluir from './categoria_evento/excluir'
import CatetoriaEventoEditar from './categoria_evento/editar'

// CRUD de Bairro
import BairroIndex from './bairro/index'
import BairroNovo from './bairro/novo'
import BairroDetalhes from './bairro/detalhes'
import BairroExcluir from './bairro/excluir'
import BairroEditar from './bairro/editar'

// CRUD de Micro Região
import MicroRegiaoIndex from './microregiao/index'
import MicroRegiaoNovo from './microregiao/novo'
import MicroRegiaoDetalhes from './microregiao/detalhes'
import MicroRegiaoExcluir from './microregiao/excluir'
import MicroRegiaoEditar from './microregiao/editar'

// CRUD de Local
import LocalIndex from './local/index'
import LocalNovo from './local/novo'
import LocalDetalhes from './local/detalhes'
import LocalExcluir from './local/excluir'
import LocalEditar from './local/editar'

// CRUD de operador
import OperadorIndex from './operador/index'
import OperadorNovo from './operador/novo'
import OperadorDetalhes from './operador/detalhes'
import OperadorExcluir from './operador/excluir'
import OperadorEditar from './operador/editar'

// CRUD de Usuario
import UsuarioIndex from './usuario/index'
import UsuarioNovo from './usuario/novo'
import UsuarioDetalhes from './usuario/detalhes'
import UsuarioExcluir from './usuario/excluir'
import UsuarioEditar from './usuario/editar'

// Denuncia
import DenunciaIndex from './denuncia/index'
import DenunciaEditar from './denuncia/editar'

// CRUD de Suporte
import SuporteIndex from './suporte/index'
// import SuporteNovo from './suporte/novo'
import SuporteDetalhes from './suporte/detalhes'
import SuporteExcluir from './suporte/excluir'
import SuporteEditar from './suporte/editar'
import { useSelector } from 'react-redux';

function RoutesContent() {

  const history = useHistory()

  const change_password = useSelector(state => state.user.change_password)

  useEffect(
    () => {
      
      if (change_password)
        history.push('./alterarsenha') // volta pra tela de pesquisa

    }, [change_password, history]
  )

  console.log('Render: Routes');
  return <Switch>

    <Route
      exact
      path="/"
      render={() => {
        return (
          change_password
            ? <Redirect to="/alterarsenha" />
            : <Redirect to="/" />
        )
      }}
    />

    <Route path='/minhaconta' render={() => <MinhaConta />} />
    <Route path='/alterarsenha' render={() => <AlterarSenha />} />

    <Route path='/notificacoes/pesquisa' render={() => <Notificacoes />} />
    <Route path='/notificacoes/novo' render={() => <NotificacaoNovo />} />
    <Route path='/notificacoes/detalhes/:id' render={() => <NotificacaoDetalhes />} />

    {/* // Rotas Administrador */}
    <Route path='/administrador/pesquisa' render={() => <AdminIndex />} />
    <Route path='/administrador/novo' render={() => <AdminNovo />} />
    <Route path='/administrador/detalhes/:id' render={() => <AdminDetalhes />} />
    <Route path='/administrador/excluir/:id' render={() => <AdminExcluir />} />
    <Route path='/administrador/editar/:id' render={() => <AdminEditar />} />

    {/* // Rotas Categoria */}
    <Route path='/categoria/pesquisa' render={() => <CatetoriaIndex />} />
    <Route path='/categoria/novo' render={() => <CatetoriaNovo />} />
    <Route path='/categoria/detalhes/:id' render={() => <CatetoriaDetalhes />} />
    <Route path='/categoria/excluir/:id' render={() => <CatetoriaExcluir />} />
    <Route path='/categoria/editar/:id' render={() => <CatetoriaEditar />} />

    
    {/* // Rotas Categoria Evento */}
    <Route path='/categoria_evento/pesquisa' render={() => <CatetoriaEventoIndex />} />
    <Route path='/categoria_evento/novo' render={() => <CatetoriaEventoNovo />} />
    <Route path='/categoria_evento/detalhes/:id' render={() => <CatetoriaEventoDetalhes />} />
    <Route path='/categoria_evento/excluir/:id' render={() => <CatetoriaEventoExcluir />} />
    <Route path='/categoria_evento/editar/:id' render={() => <CatetoriaEventoEditar />} />

    {/* // Rotas Bairro */}
    <Route path='/bairro/pesquisa' render={() => <BairroIndex />} />
    <Route path='/bairro/novo' render={() => <BairroNovo />} />
    <Route path='/bairro/detalhes/:id' render={() => <BairroDetalhes />} />
    <Route path='/bairro/excluir/:id' render={() => <BairroExcluir />} />
    <Route path='/bairro/editar/:id' render={() => <BairroEditar />} />

    {/* // Rotas Micro Região */}
    <Route path='/microregiao/pesquisa' render={() => <MicroRegiaoIndex />} />
    <Route path='/microregiao/novo' render={() => <MicroRegiaoNovo />} />
    <Route path='/microregiao/detalhes/:id' render={() => <MicroRegiaoDetalhes />} />
    <Route path='/microregiao/excluir/:id' render={() => <MicroRegiaoExcluir />} />
    <Route path='/microregiao/editar/:id' render={() => <MicroRegiaoEditar />} />

    {/* // Rotas Local */}
    <Route path='/local/pesquisa' render={() => <LocalIndex />} />
    <Route path='/local/novo' render={() => <LocalNovo />} />
    <Route path='/local/detalhes/:id' render={() => <LocalDetalhes />} />
    <Route path='/local/excluir/:id' render={() => <LocalExcluir />} />
    <Route path='/local/editar/:id' render={() => <LocalEditar />} />

    {/* // Rotas operador */}
    <Route path='/operador/pesquisa' render={() => <OperadorIndex />} />
    <Route path='/operador/novo' render={() => <OperadorNovo />} />
    <Route path='/operador/detalhes/:id' render={() => <OperadorDetalhes />} />
    <Route path='/operador/excluir/:id' render={() => <OperadorExcluir />} />
    <Route path='/operador/editar/:id' render={() => <OperadorEditar />} />

    {/* // Rotas Usuario */}
    <Route path='/usuario/pesquisa' render={() => <UsuarioIndex />} />
    <Route path='/usuario/novo' render={() => <UsuarioNovo />} />
    <Route path='/usuario/detalhes/:id' render={() => <UsuarioDetalhes />} />
    <Route path='/usuario/excluir/:id' render={() => <UsuarioExcluir />} />
    <Route path='/usuario/editar/:id' render={() => <UsuarioEditar />} />


    <Route path='/denuncia/pesquisa' render={() => <DenunciaIndex />} />
    <Route path='/denuncia/editar/:id' render={() => <DenunciaEditar />} />

    {/* // Rotas Suporte */}
    <Route path='/suporte/pesquisa' render={() => <SuporteIndex />} />
    {/* <Route path='/suporte/novo' render={() => <SuporteNovo />} /> */}
    <Route path='/suporte/detalhes/:id' render={() => <SuporteDetalhes />} />
    <Route path='/suporte/excluir/:id' render={() => <SuporteExcluir />} />
    <Route path='/suporte/editar/:id' render={() => <SuporteEditar />} />

  </Switch>
}


export default RoutesContent;
