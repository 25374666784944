import React, { Fragment } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHeader } from '../header'
import Item from './_detalhes'
import { useApi } from '../../services/api'

function Detalhes(props) {
  const history = useHistory()
  const { t } = useTranslation()
  let { id } = useParams()

  const { title } = useHeader(t('lbl.operador'), t('lbl.detalhes'), 'fal fa-user') // informa os dados do cabeçalho interno
  const [ loading, data ] = useApi(`/administrador/detalhes/${id}`, title) // faz uma requisição get na rota informada

  if (loading) return <div>{t('txt.busca_registro')}</div>

  console.log('Render: Administrador - Detalhes')
  return (
    <Fragment>
      <Item data={data} />
      <div className='page-footer'>
        <div className='row no-gutters'>
          <div className='col-sm-12 col-md-6'></div>
          <div className='col-sm-12 col-md-6 text-right'>
            <button className='btn btn-info' onClick={history.goBack}>
              {t('lbl.voltar')}
            </button>
            <Link
              to={`../editar/${data.id_administrador}`}
              params={data.id_administrador}
              className='btn btn-primary'
            >
              <i className='fal fa-pencil-alt'></i>
              {t('lbl.editar')}
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}


export default Detalhes;