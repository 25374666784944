import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

function Item(props) {
  const { t } = useTranslation()

  console.log('Render: local - Item')
  return (
    <div className='panel' style={{ height: '100%' }}>
      <div className='panel-container'>
        <div className='panel-content'>
          <dl className='dl-horizontal'>
            <dt>{t('lbl.nome')}</dt>
            <dd>{props.data.nome}</dd>
            <dt>{t('lbl.endereco')}</dt>
            <dd>{props.data.endereco}</dd>
            <dt>{t('lbl.localizacao')}</dt>
            <dd>{props.data.localizacao}</dd>
            <dt>{t('lbl.tags')}</dt>
            <dd>{props.data.tags}</dd>
            {props.data.Categorias && props.data.Categorias.length > 0 &&
              <Fragment><dt>Categorias</dt>
                <dd>
                  {props.data.Categorias.map(item => {
                    return `${item.nome} ${item.ordem ? `(${item.ordem}) ` : ' '}`
                  })
                  }
                </dd>
              </Fragment>
            }
            {props.data.Estados && props.data.Estados.length > 0 &&
              <Fragment><dt>Estados</dt>
                <dd>
                  {props.data.Estados.map(item => {
                    return `${item.Estado.nome} ${item.ordem ? `(${item.ordem}) ` : ' '}`
                  })
                  }
                </dd>
              </Fragment>
            }
            {props.data.Cidades && props.data.Cidades.length > 0 &&
              <Fragment><dt>Cidades</dt>
                <dd>
                  {props.data.Cidades.map(item => {
                    return `${item.Cidade.nome} ${item.ordem ? `(${item.ordem}) ` : ' '}`
                  })
                  }
                </dd>
              </Fragment>
            }
            {props.data.Bairros && props.data.Bairros.length > 0 &&
              <Fragment><dt>Bairros</dt>
                <dd>
                  {props.data.Bairros.map(item => {
                    return `${item.Bairro.nome} ${item.ordem ? `(${item.ordem}) ` : ' '}`
                  })
                  }
                </dd>
              </Fragment>
            }
            {props.data.MicroRegioes && props.data.MicroRegioes.length > 0 &&
              <Fragment><dt>MicroRegioes</dt>
                <dd>
                  {props.data.MicroRegioes.map(item => {
                    return `${item.MicroRegiao.nome} ${item.ordem ? `(${item.ordem}) ` : ' '}`
                  })
                  }
                </dd>
              </Fragment>
            }
            <dt>Foto</dt>
            <dd style={{ height: '100%' }}>
              <img
                alt='foto local'
                src={props.data.foto_url}
                style={{
                  width: '150px',
                  height: '150px',
                  objectFit: 'cover',
                  borderRadius: '25px'
                }} />
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Item
